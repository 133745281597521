import { act } from "react-dom/test-utils";
import {
  ADD_STREAM_MESSAGE_CONTENT,
  FETCH_STREAM_CHAT_USERS_START,
  FETCH_STREAM_CHAT_USERS_SUCCESS,
  FETCH_STREAM_CHAT_USERS_FAILURE,  
} from "../actions/ActionConstant";

const initialState = {
  streamMessages: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    loadingButtonContent: null,
    buttonDisable: false,
  }  
};

const LivestreamReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STREAM_CHAT_USERS_START:
      return {
        ...state,
        streamMessages: {
          data: {messages:[]},
          loading: false,
          error: false,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };

    case ADD_STREAM_MESSAGE_CONTENT:
      if(state.streamMessages.data && state.streamMessages.data.messages){
        state.streamMessages.data.messages.push(action.data);
      let messages = state.streamMessages.data.messages;

      
      let rem_dup_message = messages.filter(
        (v, i, a) =>
          a.findIndex(
            (v2) =>
              v.message === v2.message && v.from_user_id === v2.from_user_id && v.created===v2.created
            //&&               v.created_at != "Just Now"
          ) === i
      );
      
      return {
        ...state,
        streamMessages: {
          data: {
            messages: rem_dup_message,
            user: { ...state.streamMessages.data.user },
            total: { ...state.streamMessages.data.total },
          },
          loading: false,
          error: false,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    }
    case FETCH_STREAM_CHAT_USERS_SUCCESS:
      return {
        ...state,
        streamMessages: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
        },
      };
    case FETCH_STREAM_CHAT_USERS_FAILURE:
      return {
        ...state,
        streamMessages: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
        },
      };

   
    default:
      return state;
  }
};

export default LivestreamReducer;
