import React, { useEffect } from "react";
import { translate, t } from "react-multi-lang";
import { connect } from "react-redux";
import api from "../../Environment";
import {
  fetchSingleUserProfileStart,
} from "../../store/actions/OtherUserAction";
import { Link } from "react-router-dom";
import { Container, Row, Col, Image } from "react-bootstrap";
import configuration from "react-global-configuration";

const UserLandingPage = (props) => {
  const { userDetails } = props;
  useEffect(() => {
    props.dispatch( 
      fetchSingleUserProfileStart({
        user_unique_id: props.match.params.username,
      })
    );
  }, []);

  return (
    <>
      <div className="login-section ">
        <Container fluid className="no-padding-left">
          <Row>
            <Col
              lg={7}
              xl={7}
              md={12}
              sm={12}
              xs={12}
            >
              <img
                src={
                  "/assets/images/gina/gina.jpg"
                }
                width="100%"
                alt=""
              />
            </Col>
            <Col lg={5} xl={5} md={12} sm={12} xs={12} className="overflow-height">
              <div className="user_landing_div">
                <Link to="/" aria-current="page" className="sign-in-logo">
                  <Image
                    src={
                      api.serviceUrl() +
                      "/" +
                      configuration.get("configData.site_logo")
                    }
                    width="237"
                  />
                </Link>
                <h1 className="border-0 text_align fs-48px h-auto landing_label"><strong className="text-primary border-0 font-robot">Hi! it's Gina Knowles,</strong></h1><br /><br /><br />
                <h1 className="border-0 text_align font-robot h-60">I'm inviting you all to <br /> join me on REBEL</h1>


                {/* <ul className="footer-social-link-sec mt-15px pl-10">
                  <Media as="li" className="d-none"></Media>
                  {
                    userDetails?.data?.user?.email && (<Media as="li" className="mr-15px">
                      <a href={"mailto:" + userDetails?.data?.user?.email} >
                        <i className="fa fa-envelope fs-18px"></i>
                      </a>
                    </Media>)
                  }
                  {
                    userDetails?.data?.user?.facebook_link && (<Media as="li" className="mr-15px">
                      <a href={userDetails?.data?.user?.facebook_link}>
                        <i className="fab fa-facebook fs-18px"></i>
                      </a>
                    </Media>)
                  }
                  {
                    userDetails?.data?.user?.instagram_link && (<Media as="li" className="mr-15px">
                      <a href={userDetails?.data?.user?.facebook_link}>
                        <i className="fab fa-instagram fs-18px"></i>
                      </a>
                    </Media>)
                  }
                  {
                    userDetails?.data?.user?.twitter_link && (<Media as="li" className="mr-15px">
                      <a href={userDetails?.data?.user?.twitter_link}>
                        <i className="fab fa-twitter fs-18px"></i>
                      </a>
                    </Media>)
                  } {
                    userDetails?.data?.user?.linkedin_link && (<Media as="li" className="mr-15px">
                      <a href={userDetails?.data?.user?.linkedin_link}>
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </Media>)
                  }
                  {
                    userDetails?.data?.user?.pinterest_link && (<Media as="li" className="mr-15px">
                      <a href={userDetails?.data?.user?.pinterest_link}>
                        <i className="fab fa-pinterest fs-18px"></i>
                      </a>
                    </Media>)
                  }

                  <Media as="li" className="mr-15px">
                    <a href={""}>
                      <i className="fab fa-facebook fs-18px"></i>
                    </a>
                  </Media>
                  <Media as="li" className="mr-15px">
                    <a href={""}>
                      <i className="fab fa-twitter fs-18px"></i>
                    </a>
                  </Media>
                  <Media as="li" className="mr-15px">
                    <a href={""}>
                      <i className="fab fa-instagram fs-18px"></i>
                    </a>
                  </Media>
                  <Media as="li" className="mr-15px">
                    <a href={""}>
                      <i className="fab fa-linkedin-in fs-18px"></i>
                    </a>
                  </Media>
                  <Media as="li" className="mr-15px">
                    <a href={""}>
                      <i className="fab fa-pinterest fs-18px"></i>
                    </a>
                  </Media>
                </ul> */}
                <div className=" mt-30px button-div">
                  <a href={`/`} id="login" className="btn btn-sm mr-20px mb-30px">{t("login")}</a>
                  <a href={`/?type=signup&user_id=${userDetails?.data?.user?.user_id}`} id="login" className="btn btn-auth-light">{t("signup")}</a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  userDetails: state.otherUser.userDetails,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(UserLandingPage));
