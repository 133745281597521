import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Container, Row, Col, Image, Form, Media } from "react-bootstrap";
import { connect } from "react-redux";
import {
  postFileUploadStart,
  savePostStart,
  postFileRemoveStart,
} from "../../../store/actions/PostAction";

import { searchUserStart } from '../../../store/actions/HomeAction'

import { createNotification } from "react-redux-notify/lib/modules/Notifications";
import { getErrorNotificationMessage } from "../../helper/NotificationMessage";
import { translate, t } from "react-multi-lang";
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
// import ContentEditable from 'react-contenteditable'
// import { set } from "date-fns/esm";
// import PostEditor from "../postMentions/PostEditor";
// import {stateToHTML} from 'draft-js-export-html';
import moment from 'moment';

const CreatePostIndex = (props) => {
  const [inputData, setInputData] = useState({});
  const [checked, setChecked] = useState(false);
  const [image, setImage] = useState({ previewImage: "" });
  const [paidPost, setPaidPost] = useState(false);
  const [videoTitle, setVideoTitle] = useState("");
  const [fileUploadStatus, setFileUploadStatus] = useState(false);
  const [videoThumbnail, setVideoThumbnail] = useState(false);
  const [progress, setProgress] = useState(0);
  const mentionsRef = useRef();

  const [editorContentState, setEditorContentstate] = useState("");

  const [editorHtmlContent, setEditorHtmlContent] = useState("");

  const inputProps = { placeholder: t('publish_time'), name: 'publish_time', autoComplete: 'off' };

  // yesters time disabled
  var yesterday = moment().subtract(1, 'day');
  var valid = function (current) {
    return current.isAfter(yesterday);
  };

  const handleChangeImage = (event, fileType) => {
    setProgress(0);
    if (event.currentTarget.type === "file") {
      setFileUploadStatus(true);
      let reader = new FileReader();
      let file = event.currentTarget.files[0];
      reader.onloadend = () => {
        setImage({ ...image, previewImage: reader.result });
      };

      if (file) {
        reader.readAsDataURL(file);
      }
      props.dispatch(
        postFileUploadStart({
          file: event.currentTarget.files[0],
          file_type: fileType,
          setProgress: setProgress
        })
      );
      setPaidPost(true);
    }
  };

  const handleChangeVideo = (event, fileType) => {
    setProgress(0);
    setVideoTitle(event.currentTarget.files[0].name);
    if (event.currentTarget.type === "file") {
      setFileUploadStatus(true);
      let reader = new FileReader();
      let file = event.currentTarget.files[0];

      if (file) {
        reader.readAsDataURL(file);
      }
      props.dispatch(
        postFileUploadStart({
          file: event.currentTarget.files[0],
          file_type: fileType,
          setProgress: setProgress
        })
      );
      setPaidPost(true);
      setVideoThumbnail(true);
    }
  };

  const imageClose = (event) => {
    event.preventDefault();
    if (props.fileUpload.loadingButtonContent !== null) {
      const notificationMessage = getErrorNotificationMessage(
        "File is being uploaded.. Please wait"
      );
      props.dispatch(createNotification(notificationMessage));
    } else {
      props.dispatch(
        postFileRemoveStart({
          file: props.fileUpload.data.file,
          file_type: props.fileUpload.data.post_file.file_type,
          blur_file: props.fileUpload.data.post_file.blur_file,
          post_file_id: props.fileUpload.data.post_file.post_file_id,
        })
      );
      setImage({ previewImage: "" });
      setFileUploadStatus(false);
    }
  };

  const videoClose = (event) => {
    event.preventDefault();
    if (props.fileUpload.loadingButtonContent !== null) {
      const notificationMessage = getErrorNotificationMessage(
        "File is being uploaded.. Please wait"
      );
      props.dispatch(createNotification(notificationMessage));
    } else {
      props.dispatch(
        postFileRemoveStart({
          file: props.fileUpload.data.file ?? "",
          file_type: props.fileUpload.data.post_file ? props.fileUpload.data.post_file.file_type : "",
          preview_file: props.fileUpload.data.post_file ? props.fileUpload.data.post_file.preview_file : "",
          post_file_id: props.fileUpload.data.post_file ? props.fileUpload.data.post_file.post_file_id : "",
        })
      );
      setFileUploadStatus(false);
      setVideoTitle("");
    }
  };

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   if (fileUploadStatus) {
  //     props.dispatch(
  //       savePostStart({
  //         content: inputData.content ? inputData.content : "",
  //         amount: inputData.amount ? inputData.amount : "",
  //         post_files: props.fileUpload.data.post_file.post_file_id,
  //         preview_file: inputData.preview_file ? inputData.preview_file : "",
  //       })
  //     );
  //   } else {
  //     props.dispatch(
  //       savePostStart({
  //         content: inputData.content ? inputData.content : "",
  //         amount: inputData.amount ? inputData.amount : "",
  //       })
  //     );
  //   }
  // };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (inputData.content == undefined) {
      const notificationMessage = getErrorNotificationMessage(
        "Content is required."
      );
      props.dispatch(createNotification(notificationMessage));
    }
    else {

      let publish_time = moment().add(1, 'seconds').format('YYYY-MM-DD HH:mm:ss');
      console.log(moment(publish_time).utc().format('YYYY-MM-DD HH:mm:ss'), moment(publish_time).add(10, 'seconds').utc().format('YYYY-MM-DD HH:mm:ss'));
      if (fileUploadStatus) {

        props.dispatch(
          savePostStart({
            content: inputData.content ? inputData.content : "",
            amount: inputData.amount ? inputData.amount : "",
            post_files: props.fileUpload.data.post_file.post_file_id,
            preview_file: inputData.preview_file ? inputData.preview_file : "",
            publish_time: inputData.publish_time ?? publish_time,
            utc_time: inputData.utc_time ?? moment(publish_time).add(1, 'seconds').utc().format('YYYY-MM-DD HH:mm:ss'),
            notify: checked,
            hyperlink: inputData.hyperlink ? inputData.hyperlink : ""
          })
        );
      }
      else {
        props.dispatch(
          savePostStart({
            content: inputData.content ? inputData.content : "",
            amount: inputData.amount ? inputData.amount : "",
            publish_time: inputData.publish_time ?? publish_time,
            utc_time: inputData.utc_time ?? moment(publish_time).add(1, 'seconds').utc().format('YYYY-MM-DD HH:mm:ss'),
            notify: checked,
            hyperlink: inputData.hyperlink ? inputData.hyperlink : ""
          })
        );
      }
      // }
      // else if (moment(inputData.publish_time).local().format('YYYY-MM-DD') > moment().local().format('YYYY-MM-DD')) {

      //   if (fileUploadStatus) {
      //     props.dispatch(
      //       savePostStart({
      //         content: inputData.content ? inputData.content : "",
      //         amount: inputData.amount ? inputData.amount : "",
      //         post_files: props.fileUpload.data.post_file.post_file_id,
      //         preview_file: inputData.preview_file ? inputData.preview_file : "",
      //         publish_time: inputData.publish_time,
      //         utc_time: inputData.utc_time ?? moment(inputData.publish_time).utc().format('YYYY-MM-DD HH:mm:ss'),
      //         notify: checked
      //       })
      //     );
      //   } else {
      //     props.dispatch(
      //       savePostStart({
      //         content: inputData.content ? inputData.content : "",
      //         amount: inputData.amount ? inputData.amount : "",
      //         publish_time: inputData.publish_time,
      //         utc_time: inputData.utc_time ?? moment(inputData.publish_time).utc().format('YYYY-MM-DD HH:mm:ss'),
      //         notify: checked
      //       })
      //     );
      //   }
      // }
    }

  };

  const handleDate = (event) => {
    let date = event.year() + '-' + (((event.month() + 1) > 9) ? (event.month() + 1) : ('0' + (event.month() + 1))) + '-' + ((event.date() > 9) ? event.date() : ('0' + event.date())) + ' ' + ((event.hour() > 9) ? event.hour() : ('0' + event.hour())) + ':' + ((event.minute() > 9) ? event.minute() : ('0' + event.minute()));
    //console.log( moment(date).add( 2, 'minute' ).format())
    //console.log(strtotime(moment().local().format('YYYY-MM-DD HH:mm')),strtotime(date));

    setInputData({ ...inputData, utc_time: moment(date).utc().format('YYYY-MM-DD HH:mm:ss') });
    setInputData({ ...inputData, publish_time: moment(date).format('YYYY-MM-DD HH:mm:ss') });
  }

  return (
    <div className="notification-page create-post" id="tabs">
      <Container>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col sm={12} md={12}>
              <div className="post-create-header">
                <div className="pull-left">
                  <Link className="bookmarkes-list notify-title" to={"/home"}>
                    <Image
                      src="/assets/images/icons/back.svg"
                      className="svg-clone"
                    />
                    {t("new_post")}
                  </Link>
                </div>
                <div className="pull-right">
                  <Button
                    type="submit"
                    className="post-btn btn-primary"
                    onClick={handleSubmit}
                    disabled={
                      props.fileUpload.buttonDisable ||
                      props.savePost.buttonDisable
                    }
                  >
                    {props.fileUpload.loadingButtonContent !== null
                      ? props.fileUpload.loadingButtonContent + " " + progress + "%"
                      : props.savePost.loadingButtonContent !== null
                        ? props.savePost.loadingButtonContent
                        : t("post")}
                  </Button>
                </div>
              </div>
            </Col>

            <Col sm={12} md={12}>
              <div className="create-post-textarea">
                <Form.Group>
                  <Form.Control
                    as="textarea"
                    className="border-bottom"
                    rows={3}
                    placeholder={t("new_post_placeholder")}
                    name="content"
                    style={{ width: "100%", maxWidth: "100%" }}
                    value={inputData.content ? inputData.content : null}
                    onChange={(event) =>
                      setInputData({
                        ...inputData,
                        content: event.currentTarget.value
                      })
                    }
                  />
                </Form.Group>
              </div>
              <Datetime inputProps={inputProps} onChange={(event) => handleDate(event)} dateFormat="YYYY-MM-DD" isValidDate={valid} />
              {/* <div className="searchMentions">

                <div className="PostEditor">
                  <PostEditor
                    className="PostEditor__input"
                    placeholder={"What are you thinking"}
                    ref={mentionsRef}
                    getEditorRawContent={setEditorContentstate}
                    getEditorHtmlContent = {setEditorHtmlContent}
                  />
                  </div>  
              </div> */}

              {localStorage.getItem("is_document_verified") == 2 ? (
                <div className="left-half post-write">
                  <Button>
                    <Form.Group className="mb-0">
                      <Form.Control
                        id="fileupload_photo"
                        type="file"
                        multiple="multiple"
                        accept=".gif,.jpg,.jpeg,.gif,.png,.jpg,.jpeg,.png"
                        onChange={(event) => handleChangeImage(event, "image")}
                        name="post_files"
                      />
                      <Form.Label
                        id="attach_file_photo"
                        htmlFor="fileupload_photo"
                        className="chat-attach_file"
                        data-original-title="null"
                      >
                        <Image
                          src="/assets/images/icons/gallery.svg"
                          className="svg-clone"
                        />
                      </Form.Label>
                    </Form.Group>
                  </Button>
                  <Button>
                    <Form.Group className="mb-0">
                      <Form.Control
                        id="fileupload_video"
                        type="file"
                        multiple="multiple"
                        accept="video/mp4,video/x-m4v,video/*"
                        onChange={(event) => handleChangeVideo(event, "video")}
                        name="post_files"
                      />
                      <Form.Label
                        id="attach_file_video"
                        htmlFor="fileupload_video"
                        className="chat-attach_file"
                        data-original-title="null"
                      >
                        <Image
                          src="/assets/images/icons/video.svg"
                          className="svg-clone"
                        />
                      </Form.Label>
                    </Form.Group>
                  </Button>
                  {videoTitle !== "" ? (
                    <div className="post-title-content">
                      <h4>{videoTitle}</h4>
                      <Link to="#" onClick={videoClose}>
                        <i className="far fa-window-close"></i>
                      </Link>
                    </div>
                  ) : null}
                </div>
              ) : (
                ""
              )}
              {image.previewImage !== "" ? (
                <Row>
                  <Col sm={12} md={3}>
                    <div className="post-img-preview-sec">
                      <Link to="#" onClick={imageClose}>
                        <i className="far fa-times-circle"></i>
                      </Link>
                      <Image
                        alt="#"
                        src={image.previewImage}
                        className="post-video-preview"
                      />
                    </div>
                  </Col>
                </Row>
              ) : null}
            </Col>
            {paidPost == true ? (
              <Col sm={12} md={12}>
                <Form.Group className="md-mrg-btm">
                  <label className="text-muted m-1 h3">
                    {t("price")} ({t("optional")})
                  </label>
                  <Form.Control
                    type="number"
                    placeholder={t("price_placeholder")}
                    name="amount"
                    pattern="[0-9]*"
                    min="1"
                    inputMode="numeric"
                    value={inputData.amount}
                    width="50%"
                    onChange={(event) =>
                      setInputData({
                        ...inputData,
                        amount: event.currentTarget.value,
                      })
                    }
                  />
                </Form.Group>
              </Col>
            ) : (
              ""
            )}
            {videoThumbnail === true ? (
              <Col sm={12} md={12}>
                <Form.Group className="md-mrg-btm">
                  <label className="text-muted m-1 mt-3 f-12 text-uppercase">
                    {t("upload_video_thumbnail")}:({t("optional")})
                  </label>
                  <Form.Control
                    style={{ display: "block" }}
                    type="file"
                    placeholder={t("upload_video_thumbnail_placeholder")}
                    name="preview_file"
                    width="50%"
                    className="form-control"
                    accept=".gif,.jpg,.jpeg,.gif,.png,.jpg,.jpeg,.png"
                    onChange={(event) =>
                      setInputData({
                        ...inputData,
                        preview_file: event.currentTarget.files[0],
                      })
                    }
                  />
                </Form.Group>
              </Col>
            ) : (
              ""
            )}
          </Row>

          {localStorage.getItem("user_unique_id") == "rebel" &&
            <Row>
              <br />
              <br />
              <Col sm={12} md={12}>
                <div className="create-post-textarea margin-top-4">
                  <Form.Group>
                    <Form.Control
                      as="textarea"
                      rows={1}
                      className="border-bottom "
                      placeholder={t("hyperlink")}
                      name="hyperlink"
                      style={{ width: "100%", maxWidth: "100%" }}
                      value={inputData.hyperlink ? inputData.hyperlink : null}
                      onChange={(event) =>
                        setInputData({
                          ...inputData,
                          hyperlink: event.currentTarget.value
                        })
                      }
                    />
                  </Form.Group>
                </div>
              </Col>
            </Row>
          }

          <Row>
            <Col sm={12} md={12}>

              <br />
              <div className="create-post-textarea">
                <Form.Group>
                  <Form.Check
                    inline
                    label={t("notify_subscriber_for_new_post")}
                    name="access"
                    type="checkbox"
                    id={`inline-access-radio-1`}
                    onChange={(e) => {
                      console.log("dhjkfdjk")
                      setChecked(!checked);
                    }}
                    className="text-gray"
                  />
                </Form.Group>
              </div>

            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
};

const mapStateToPros = (state) => ({
  savePost: state.post.savePost,
  fileUpload: state.post.fileUpload,
  searchUser: state.home.searchUser,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(CreatePostIndex));
