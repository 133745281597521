import {
  ADD_STREAM_MESSAGE_CONTENT,
  FETCH_STREAM_CHAT_USERS_START,
  FETCH_STREAM_CHAT_USERS_FAILURE,
  FETCH_STREAM_CHAT_USERS_SUCCESS,
  DELETE_STREAM_CHAT_START,
  DELETE_STREAM_CHAT_SUCCESS,
  DELETE_STREAM_CHAT_FAIL
} from "./ActionConstant";

export function fetchStreamChatUsersStart(data) {
  return {
    type: FETCH_STREAM_CHAT_USERS_START,
    data,
  };
}

export function fetchStreamChatUsersSuccess(data) {
  return {
    type: FETCH_STREAM_CHAT_USERS_SUCCESS,
    data,
  };
}

export function fetchStreamChatUsersFailure(error) {
  return {
    type: FETCH_STREAM_CHAT_USERS_FAILURE,
    error,
  };
}
export function addStreamMessageContent(data) {
  return {
    type: ADD_STREAM_MESSAGE_CONTENT,
    data,
  };
}

export function deleteStreamChatStart(data) {
  return {
    type: DELETE_STREAM_CHAT_START,
    data,
  };
}

export function deleteStreamChatSuccess(data) {
  return {
    type: DELETE_STREAM_CHAT_SUCCESS,
    data,
  };
}

export function deleteStreamChatFailure(error) {
  return {
    type: DELETE_STREAM_CHAT_FAIL,
    error,
  };
}