import React, { useEffect, useState, useRef } from "react";
import moment from "moment"
import {
  Form,
  Button,
  Row,
  Col,
  Image,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { translate, t } from "react-multi-lang";
import { connect } from "react-redux";
import api from "../../Environment";
import "./LiveStream.css";
import {
  fetchSingleStreamStart,
  saveLiveStreamUsersStart,
  leaveLiveStreamUserStart,
} from "../../store/actions/PostAction";
import AgoraVideoCall from "../../components/LiveStream/AgoraVideoCall";
import { fetchSingleUserStreamProfileStart } from "../../store/actions/OtherUserAction";
import io from "socket.io-client";
import {
  fetchStreamChatUsersStart,
  addStreamMessageContent,
  deleteStreamChatStart
} from "../../store/actions/Livestream";
import { getErrorNotificationMessage } from "../helper/NotificationMessage";
import { SocketState } from '../Socket/SocketProvider'

const LiveStream = (props) => {
  let client, config, localStream;
  const [user_count, setUserCount] = useState(0);
  const [Messages, setMessages] = useState("");
  const [inputMessage, setInputMessage] = useState("");
  const [initialHeight, setInitialHeight] = useState(0);
  const messageRef = useRef();
  const [count_msg, setCountMsg] = useState(0);
  const { liveStreamSocket, liveStreamSocketUrl, setLiveStreamSocket, setLiveStreamSocketUrl } = SocketState()

  useEffect(() => {

    if (liveStreamSocketUrl && liveStreamSocketUrl != "" && Object.keys(liveStreamSocket).length == 0) {
      console.log("user connect 2")
      setLiveStreamSocket(
        io(liveStreamSocketUrl, {
          query:
            `commonid:'user_id_` +
            localStorage.getItem("userId") +
            `',myid:` +
            localStorage.getItem("userId"),
        })
      );
    }
    props.dispatch(
      fetchSingleStreamStart({
        stream_id: props.match.params.stream_id,
      })
    );
  }, []);


  useEffect(() => {
    if (localStorage.getItem("userId") != props.livestream.data.host_id) {
      props.dispatch(
        fetchSingleUserStreamProfileStart({
          user_unique_id: props.livestream.data.host_unique_id,
        })
      );
    }
  }, [props.livestream.loading]);

  if (!props.livestream.loading) {
    config = {
      mode: props.livestream.data.mode,
      codec: props.livestream.data.codec,
      appId: props.livestream.data.appId,
      token: props.livestream.data.token,
      channel: props.livestream.data.channel,
      transcode: props.livestream.data.transcode,
      videoProfile: props.livestream.data.videoProfile,
      baseMode: props.livestream.data.baseMode,
      uid: props.livestream.data.user_id,
      unique_id: props.livestream.data.unique_id,
    };

    // props.dispatch(
    //   saveLiveStreamUsersStart({
    //     live_stream_id: props.livestream.data.stream_id,
    //     user_id: ids,
    //   })
    // );
  }

  useEffect(() => {
    props.dispatch(
      fetchStreamChatUsersStart({ stream_id: props.match.params.stream_id })
    );


    if (liveStreamSocketUrl) {
      const notificationMessage = getErrorNotificationMessage(
        "Socket URL is not configured. Chat wil not work..."
      );
    }

    if (messageRef.current) {
      messageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  }, []);


  useEffect(() => {
    if (
      props.streamMessages.data.messages &&
      props.streamMessages.data.messages.length
    ) {
      setMessages(props.streamMessages.data.messages);
    }
  }, [props.streamMessages.data.messages]);

  // Scroll down function..
  useEffect(() => {
    const objDiv = document.getElementById("options-holder");

    if (objDiv != null) {
      let differenceNumber =
        objDiv.offsetHeight > objDiv.scrollHeight
          ? objDiv.offsetHeight - objDiv.scrollHeight
          : objDiv.scrollHeight - objDiv.offsetHeight;

      if (differenceNumber > 280) {
        objDiv.scrollTop = objDiv.scrollHeight;
      } else {
        objDiv.scrollTop = initialHeight;
        setInitialHeight(initialHeight + 20);
      }
    }
  }, [props.streamMessages.data.messages]);

  const handleChatSubmit = async (event) => {
    event.preventDefault();

    if (liveStreamSocket && Object.keys(liveStreamSocket).length != 0 && inputMessage) {
      let chatData = [
        {
          from_user_id: localStorage.getItem("userId"),
          host_id: props.livestream.data.host_id,
          user_id: localStorage.getItem("userId"),
          stream_id: props.match.params.stream_id,
          message: inputMessage,
          type: "uu",
          user_picture: localStorage.getItem("user_picture"),
          loggedin_user_id: localStorage.getItem("userId"),
          created: Date(),
          from_username: localStorage.getItem("username"),
          from_displayname: localStorage.getItem("name"),
          from_userpicture: localStorage.getItem("user_picture"),
          from_user_unique_id: "",
          to_username: "",
          to_displayname: "",
          to_userpicture: "",
          to_user_unique_id: "",
        },
      ];

      await liveStreamSocket.emit("send_message", chatData[0]);

      props.dispatch(addStreamMessageContent(chatData[0]));
      setInputMessage("");
    }
  };

  useEffect(() => {
    if (liveStreamSocket && Object.keys(liveStreamSocket).length != 0) {
      liveStreamSocket.on("receive_message", (newData) => {
        // setInputMessage("");

        // console.log("newData", newData);
        if (Messages && Messages.length) {
          setMessages(...Messages, newData);
        } else {
          setMessages(newData);
        }
        // console.log("-------", Messages);
        props.dispatch(addStreamMessageContent(newData));
      });
    }
  }, [liveStreamSocket]);

  const chatInputChange = (value) => {
    setInputMessage(value);
  }

  let handleUserUpdate = (id, event) => {
    // props.dispatch(
    //   GetLiveStreamUsers({ live_stream_id: props.livestream.data.stream_id })
    // );

    props.dispatch(
      saveLiveStreamUsersStart({
        live_stream_id: props.livestream.data.stream_id,
        user_id: id,
      })
    );

    // if (liveStreamSocketUrl === "") {
    //   console.log("no keys configured");
    // } else {
    //   //socket start

    //   // if (liveStreamSocketUrl) {
    //   //   liveStreamSocket = io(liveStreamSocketUrl, {
    //   //     query:
    //   //       `commonid:'user_id_` +
    //   //       localStorage.getItem("userId") +
    //   //       `_to_user_id_` +
    //   //       "" +
    //   //       `',myid:` +
    //   //       localStorage.getItem("userId"),
    //   //   });
    //   if (liveStreamSocket) {
    //     liveStreamSocket.emit("video-call-join", {
    //       host_id: props.livestream.data.host_id,
    //       user_id: props.livestream.data.user_id,
    //       stream_id: props.livestream.data.stream_id,
    //     });

    //     liveStreamSocket.on("livestream_user_count", (newData) => {
    //       //console.log("newData", newData);
    //       setUserCount(newData);
    //     });

    //   }
    // }
  };


  let handleUserJoin = async (id, event) => {

    // await props.dispatch(
    //   saveLiveStreamUsersStart({
    //     live_stream_id: props.livestream.data.stream_id,
    //     user_id: id,
    //   })
    // );

    if (liveStreamSocketUrl === "") {
      console.log("no keys configured");
    } else {
      //socket start
      if (liveStreamSocket && Object.keys(liveStreamSocket).length != 0) {

        await liveStreamSocket.emit("video-call-join", {
          host_id: props.livestream.data.host_id,
          user_id: props.livestream.data.user_id,
          stream_id: props.livestream.data.stream_id,
        });

        await liveStreamSocket.on("livestream_user_count", (newData) => {
          console.log("newData", newData);
          setUserCount(newData);
        });

      }
    }
  };


  let handleUserLeave = (id, event) => {
    // props.dispatch(
    //   leaveLiveStreamUserStart({
    //     live_stream_id: props.livestream.data.stream_id,
    //     user_id: id,
    //   })
    // );

    // if (props.livestream.data.host_id == Number(id)) {
    //   console.log(props.livestream.data.host_id, id);
    //   props.dispatch(
    //     deleteStreamChatStart({
    //       stream_id: props.livestream.data.stream_id
    //     })
    //   );
    // }

    if (liveStreamSocketUrl === "") {
      console.log("no keys configured");
    } else {
      //socket start

      // if (liveStreamSocketUrl) {
      //   liveStreamSocket = io(liveStreamSocketUrl, {
      //     query:
      //       `commonid:'user_id_` +
      //       localStorage.getItem("userId") +
      //       `_to_user_id_` +
      //       "" +
      //       `',myid:` +
      //       localStorage.getItem("userId"),
      //   });
      if (liveStreamSocket && Object.keys(liveStreamSocket).length != 0) {
        liveStreamSocket.emit("video-call-exit", {
          host_id: props.livestream.data.host_id,
          user_id: props.livestream.data.user_id,
          stream_id: props.livestream.data.stream_id,
        });

        liveStreamSocket.on("livestream_user_count", (newData) => {
          console.log("newData", newData);
          setUserCount(newData);
        });

      }
      else {
        // liveStreamSocket.disconnect();
      }

      // }
      //socket end
    }
  };
  const { userDetails } = props;


  if (!props.livestream.loading) {
    config = {
      mode: props.livestream.data.mode,
      codec: props.livestream.data.codec,
      appId: props.livestream.data.appId,
      token: props.livestream.data.token,
      channel: props.livestream.data.channel,
      transcode: props.livestream.data.transcode,
      videoProfile: props.livestream.data.videoProfile,
      baseMode: props.livestream.data.baseMode,
      uid: props.livestream.data.user_id,
    };
  }

  return (
    <div className="wrapper meeting">
      <div className="ag-header">
        {!props.livestream.loading ? (
          <div className="ag-header-msg">
            Room:&nbsp;
            <span id="room-name">{props.livestream.data.channel}</span>
          </div>
        ) : (
          <div></div>
        )}
      </div>
      <div className="ag-main1">
        <div className="ag-container">
          <Row style={{ height: "100%" }}>
            <Col sm={9}>
              {" "}
              {props.livestream.loading ? (
                <div></div>
              ) : (
                <AgoraVideoCall
                  videoProfile={props.livestream.data.videoProfile}
                  channel={props.livestream.data.channel}
                  transcode={props.livestream.data.transcode}
                  attendeeMode={props.livestream.data.mode}
                  baseMode={props.livestream.data.baseMode}
                  appId={props.livestream.data.appId}
                  uid={props.livestream.data.user_id}
                  host_id={props.livestream.data.host_id}
                  stream_id={props.livestream.data.stream_id}
                  codec={props.livestream.data.codec}
                  token={props.livestream.data.token}
                  host_user={userDetails && userDetails?.data?.user}
                  user_count={user_count}
                  user_update={handleUserUpdate}
                  user_join={handleUserJoin}
                  user_leave={handleUserLeave}
                  dispatch={props.dispatch}
                />
              )}
            </Col>
            <Col sm={3} className="stream-msg">
              {props.streamMessages.loading ? (
                ""
              ) : (
                <Row className="msg-row-chat">
                  <div className="msg-header1">
                    <h1 className="chat-section-title">
                      <div className="chat-section-title-width">
                        <h4 className="chat-user-name">
                          {props.livestream.data.title}{" "}
                        </h4>
                      </div>
                    </h1>
                    <div className="chat-subheader hidden">
                      <div className="chat-user-status">
                        {t("last_seen")}{" "}
                        <span title="Sep 7, 3:12 pm"> 1 {t("hour_ago")} </span>
                      </div>

                      <Button type="button" className="chat-subheader-btn">
                        <Image
                          src="/assets/images/icons/star.svg"
                          className="svg-clone"
                          width="12"
                        />
                      </Button>
                      <Button type="button" className="chat-subheader-btn">
                        <Image
                          src="/assets/images/icons/notification.svg"
                          className="svg-clone"
                          width="12"
                        />
                      </Button>
                      <Button type="button" className="chat-subheader-btn">
                        <Image
                          src="/assets/images/icons/gallery.svg"
                          className="svg-clone"
                          width="12"
                        />

                        <span>{t("gallery")}</span>
                      </Button>
                      <Button type="button" className="chat-subheader-btn">
                        <Image
                          src="/assets/images/icons/search.svg"
                          className="svg-clone"
                          width="12"
                        />

                        <span>{t("find")}</span>
                      </Button>
                    </div>
                  </div>

                  <div className="chat-area">
                    <div className="chat-wrapper scrollbar" id="options-holder">
                      <div
                        className="chat-message padding overflow-height"
                        id="check"
                        ref={messageRef}
                      >
                        {props.streamMessages.data.messages &&
                          props.streamMessages.data.messages.length > 0
                          ? props.streamMessages.data.messages.map(
                            (chatMessage, index) => (
                              <>
                                {chatMessage.from_user_id ==
                                  localStorage.getItem("userId") ? (
                                  <div className="chat-message chat-message-recipient" key={index}>
                                    <Image
                                      className="chat-image chat-image-default"
                                      src={
                                        chatMessage.from_userpicture && chatMessage.from_userpicture != "null"
                                          ? api.serviceUrl() +
                                          "/" +
                                          chatMessage.from_userpicture
                                          : "/assets/images/upload-profile-img.svg"
                                      }
                                    />

                                    <div className="chat-message-wrapper">

                                      <div className="chat-message-content">
                                        <p><b>{chatMessage.from_displayname}</b></p>

                                        <p className="font-14">{chatMessage.message}</p>
                                      </div>

                                      <div className="chat-details">
                                        <span className="chat-message-localization font-size-small">
                                          {moment(chatMessage.created_at).fromNow()}
                                        </span>
                                        <span className="chat-message-read-status font-size-small"></span>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="chat-message chat-message-recipient">
                                    <Image
                                      className="chat-image chat-image-default"
                                      src={
                                        chatMessage.from_userpicture && chatMessage.from_userpicture != "null"
                                          ? api.serviceUrl() +
                                          "/" +
                                          chatMessage.from_userpicture
                                          : "/assets/images/upload-profile-img.svg"
                                      }
                                    />

                                    <div className="chat-message-wrapper">
                                      <div className="chat-message-content">
                                        <p><b>{chatMessage.from_displayname}</b></p>

                                        <p className="font-14">{chatMessage.message}</p>
                                      </div>

                                      <div className="chat-details">
                                        <span className="chat-message-localization font-size-small">
                                          {moment(chatMessage.created_at).fromNow()}
                                        </span>
                                        {/* <span className="chat-message-read-status font-size-small">
                                          , $69 not paid yet
                                        </span> */}
                                      </div>
                                    </div>
                                  </div>
                                )}{" "}
                              </>
                            )
                          )
                          : ""}
                      </div>
                    </div>

                    <div
                      style={{
                        borderTop: "1px solid rgba(138, 150, 163, 0.2)",
                      }}
                    >
                      <Form
                        id="chat_post_form"
                        className="has-advanced-upload"
                        onSubmit={handleChatSubmit}
                      >
                        <div className="chats-post-footer">
                          <div className="chat-post">
                            <div className="chat-textarea-price-wrapper">

                              <InputGroup >
                                <FormControl
                                  controlid="chat-input-area"
                                  placeholder="Type a message"
                                  autoComplete="off"
                                  name="text"
                                  rows="1"
                                  className="form-control chat-input1"
                                  style={{
                                    overflow: "hidden",
                                    overflowWrap: "break-word",
                                    height: "48px",
                                    minHeight: "58px",
                                  }}
                                  value={inputMessage}
                                  onChange={(event) => {
                                    chatInputChange(event.currentTarget.value);
                                  }}
                                />
                                <InputGroup.Append>
                                  <InputGroup.Text id="basic-addon2" className="input-group-live-text">
                                    <Button
                                      type="button"
                                      data-can_send="true"
                                      className="g-btn m-rounded b-chat__btn-submit"
                                      onClick={handleChatSubmit}
                                    >
                                      <Image
                                        src="/assets/images/icons/send-white.svg"
                                        className="svg-clone"
                                      />
                                    </Button>
                                  </InputGroup.Text>
                                </InputGroup.Append>
                              </InputGroup>
                            </div>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>
                </Row>
              )}
            </Col>
          </Row>
        </div>
      </div>
      <div className="ag-footer"></div>
    </div>
  );
};

const mapStateToPros = (state) => ({
  livestream: state.post.livestream,
  userDetails: state.otherUser.userDetails,
  streamMessages: state.livestream.streamMessages,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(LiveStream));
