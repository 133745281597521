export const apiConstants = {
  // Common variables

  LOGIN_BY: "manual",

  DEVICE_TYPE: "web",

  DEVICE_TOKEN: "123456",

  // Social Login Credentials

  GOOGLE_CLIENT_ID: "",

  FACEBOOK_APP_ID: "",

  ERROR_CODE: [1000, 1001, 1002, 1003, 1004, 1005, 1006],

  settingsUrl: process.env.REACT_APP_API_URL + "get_settings_json",
};

//"https://admin.rebeltv.me/api/user/get_settings_json",

//"http://localhost:8000/api/user/get_settings_json"
