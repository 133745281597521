import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import {
  VIEW_POST_VIDEO_START
} from "../actions/ActionConstant";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import {
  videoViewPostSuccess,
  videoViewPostFailure,
} from "../actions/PostAction";

function* savePostVideoViewsAPI() {
  try {
    const inputData = yield select(
      (state) => state.postVideoViews.savePostVideoView.inputData
    );
    const response = yield api.postMethod("post_video_views_save", inputData);
  } catch (error) {

  }
}

export default function* pageSaga() {
  yield all([yield takeLatest(VIEW_POST_VIDEO_START, savePostVideoViewsAPI)]);
}
