import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import {
  fetchStreamChatUsersSuccess,
  fetchStreamChatUsersFailure,
  deleteStreamChatSuccess,
  deleteStreamChatFailure

} from "../actions/Livestream";
import {
  FETCH_STREAM_CHAT_USERS_START,
  DELETE_STREAM_CHAT_START
} from "../actions/ActionConstant";

function* fetchStreamChatMessageAPI(inputData) {
  try {
    //const inputData = yield select((state) => state.chat.messages.inputData);
    const response = yield api.postMethod(
      "fetch_stream_messages",
      inputData.data
    );
    if (response.data.success) {
      yield put(fetchStreamChatUsersSuccess(response.data.data));
    } else {
      yield put(fetchStreamChatUsersFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchStreamChatUsersFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}


function* deleteStreamChatMessageAPI(inputData) {
  try {
    console.log("dcjkhsdjkfs")
    const response = yield api.postMethod(
      "stream_chat_messages_delete",
      inputData.data
    );
    if (response.data.success) {
      yield put(deleteStreamChatSuccess(response.data.data));
    } else {
      yield put(fetchStreamChatUsersFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(deleteStreamChatFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(FETCH_STREAM_CHAT_USERS_START, fetchStreamChatMessageAPI),
    yield takeLatest(DELETE_STREAM_CHAT_START, deleteStreamChatMessageAPI),
    
  ]);
}
