import React, { useState } from "react";
import {Form, Button, Image, Modal} from "react-bootstrap";
import { translate, t } from "react-multi-lang";
import { saveSingleCallStart } from "../../store/actions/PostAction";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import moment from 'moment';

const VideoScheduleModal = (props) => {
  
  const [value, onChange] = useState(new Date());

  const [validate, setValidate] = useState(false);

  let yesterday = moment().subtract( 1, 'day' );
  let valid = function( current ){
      return current.isAfter( yesterday );
  };

  let handleVideoCallSchedule = (ids,event) => {
    let dateval = new Date(value);
    let dateCurr = new Date();
    let day = dateval.getDate();
    let month = dateval.getMonth()+1;
    let year = dateval.getFullYear();
    let hours = dateval.getHours();
    let min = dateval.getMinutes();
    let sec = dateval.getSeconds();
    let date = year+"-"+month+"-"+day+" "+hours+":"+min+":"+sec;
    
    if(dateCurr.getTime() > dateval.getTime()){
      setValidate(true);
      return false;
    }
    else{
      setValidate(false);
      props.dispatch(saveSingleCallStart({
        user_id: props.userId,
        schedule_at: date,
      }));
      onChange(new Date());
      props.closeVideoScheduleModal();
    }
  };

  return (
    <>
      <Modal
        className="modal-dialog-center report-modal"
        size="md"
        centered
        show={props.videoSchedule}
        onHide={props.closeVideoScheduleModal}
      >
        <Form>
          <Modal.Header closeButton>
            <Modal.Title>{t("schedule")} {t("date")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <div className="report-form">
                <Form>                    
                  <Form.Group>
                    <Datetime
                      onChange={onChange}
                      value={value}
                      isValidDate={ valid }
                      id="schedule_at"
                    />
                  </Form.Group>
                </Form>
                {(validate)?
                  <Form>                    
                    <Form.Group>
                      <span className="text-danger">{t("date_error")}</span>
                    </Form.Group>
                  </Form>
                :
                  <div>
                  </div>
                }
              </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="button"
              className="btn btn-normal"
              onClick={() => props.closeVideoScheduleModal()}
            >
              {t("cancel")}
            </Button>
            <Button
              type="button"
              className="btn btn-theme"
              onClick={() => handleVideoCallSchedule()}
            >
              {t("schedule")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

const mapStateToPros = (state) => ({
  saveCall: state.post.saveCallUser,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(VideoScheduleModal));

