import React, { Component } from "react";
import configuration from "react-global-configuration";
import { Link } from "react-router-dom";
import { Form, Container, Row, Col, Dropdown, Media } from "react-bootstrap";
import { translate, t } from "react-multi-lang";

import {
  setTranslations,
  setDefaultLanguage,
  setLanguage,
  getLanguage,
} from "react-multi-lang";
import en from "../../translations/en.json";
import es from "../../translations/es.json";

class NewFooter extends Component {
  handleChangeLang = ({ currentTarget: input }) => {
    console.log(input.value);
    setLanguage(input.value);
    localStorage.setItem("lang", input.value);
    // window.location.reload();
  };

  render() {
    return (
      <>
        <footer className="new-footer-sec">
          <Container>
            <div className="footer-sec">
              <Row>
                <Col className="resp-col-width resp-mrg-btn-xs">
                    <h4>{configuration.get("configData.contact_address")} <br></br> {configuration.get("configData.contact_mobile")}</h4>
                    <h4>
                        {configuration.get("configData.copyright_content")}
                    </h4>
                    {/* <ul className="footer-link-sec list-unstyled">
                        {configuration
                        .get("configData.footer_pages1")
                        .map((static_page, index) => (
                            <Media
                            as="li"
                            key={"sp-" + static_page.static_page_unique_id}
                            >
                            <Link
                                to={`/page/${static_page.static_page_unique_id}`}
                                key={static_page.static_page_unique_id}
                            >
                                {static_page.title}
                            </Link>
                            </Media>
                        ))}
                    </ul> */}
                    <ul className="footer-social-link-sec list-unstyled">
                        <Media as="li">
                            <a href={configuration.get("configData.facebook_link")}>
                                <i className="fab fa-facebook"></i>
                            </a>
                        </Media>
                        <Media as="li">
                            <a href={configuration.get("configData.twitter_link")}>
                                <i className="fab fa-twitter"></i>
                            </a>
                        </Media>
                        <Media as="li">
                            <a href={configuration.get("configData.instagram_link")}>
                                <i className="fab fa-instagram"></i>
                            </a>
                        </Media>
                        <Media as="li">
                            <a href={configuration.get("configData.linkedin_link")}>
                                <i className="fab fa-linkedin-in"></i>
                            </a>
                        </Media>
                        <Media as="li">
                            <a href={configuration.get("configData.pinterest_link")}>
                                <i className="fab fa-pinterest"></i>
                            </a>
                        </Media>
                        <Media as="li">
                            <a href={configuration.get("configData.youtube_link")}>
                                <i className="fab fa-youtube"></i>
                            </a>
                        </Media>
                        <Media as="li">
                            <a href={configuration.get("configData.snapchat_link")}>
                                <i className="fab fa-snapchat"></i>
                            </a>
                        </Media>
                    </ul>
                </Col>
                <Col className="resp-col-width resp-mrg-btn-xs">
                    <ul className="footer-link-sec list-unstyled">
                        <Media as="li">
                            <h5 className="bold-cls">
                                {configuration.get("configData.site_name")}
                            </h5>
                        </Media>
                        {configuration
                            .get("configData.footer_pages1")
                            .map((static_page, index) => (
                                <Media
                                as="li"
                                key={"sp-" + static_page.static_page_unique_id}
                                >
                                <Link
                                    to={`/page/${static_page.static_page_unique_id}`}
                                    key={static_page.static_page_unique_id}
                                >
                                    {static_page.title}
                                </Link>
                                </Media>
                        ))}
                    </ul>
                </Col>

                <Col className="resp-col-width resp-mrg-btn-xs">
                    <ul className="footer-link-sec list-unstyled">
                        <Media as="li">
                            <h5 className="bold-cls">
                                Discover
                            </h5>
                        </Media>
                        {configuration
                        .get("configData.footer_pages2")
                        .map((static_page, index) => (
                            <Media
                            as="li"
                            key={"sp-" + static_page.static_page_unique_id}
                            >
                            <Link
                                to={`/page/${static_page.static_page_unique_id}`}
                                key={static_page.static_page_unique_id}
                            >
                                {static_page.title}
                            </Link>
                            </Media>
                        ))}
                    </ul>
                </Col>
                
                {/* <Col className="resp-col-width">
                    <ul className="footer-link-sec list-unstyled">
                        <Media as="li">
                            <Link to="#">
                                Share
                            </Link>
                        </Media>
                        <Media as="li">
                            <Link to="#">
                                How it works
                            </Link>
                        </Media>
                        <Media as="li">
                            <Link to="#">
                               Complaints Policy
                            </Link>
                        </Media>
                    </ul>
                </Col> */}
                <Col className="resp-col-width">
                    <ul className="footer-link-sec list-unstyled">
                        
                        {configuration.get("configData.is_multilanguage_enabled") ==
                        1 ? (
                            <>
                                <Media as="li">
                                    <h5 className="bold-cls">
                                        Language
                                    </h5>
                                </Media>
                                <div className="select-lang-drop-down">
                                <select
                                    className="form-control mw-200 mb-3"
                                    onChange={this.handleChangeLang}
                                    name="lang"
                                    defaultValue={localStorage.getItem("lang")}
                                >
                                    <option
                                    value="en"
                                    // selected={
                                    //     localStorage.getItem("lang") == "en" ? true : false
                                    // }
                                    >
                                    {t("english")}
                                    </option>
                                    <option
                                    value="es"
                                    // selected={
                                    //     localStorage.getItem("lang") == "es" ? true : false
                                    // }
                                    >
                                    {t("spanish")}
                                    </option>
                                </select>
                                </div>
                            </>
                        ) : (
                            ""
                        )}
                        {/* <Media as="li">
                            <Link to="#">
                                Refferals
                            </Link>
                        </Media>
                        <Media as="li">
                            <Link to="#">
                               Standard Contract between Fan and Creator
                            </Link>
                        </Media> */}
                    </ul>
                </Col>
              </Row>
            </div>
          </Container>
        </footer>
      </>
    );
  }
}

export default translate(NewFooter);
