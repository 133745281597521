import React, { useEffect } from "react";
import userLogoutStart from "../../store/actions/UserAction";
import { connect } from "react-redux";
import { translate } from "react-multi-lang";

const Logout = (props) => {
  useEffect(() => {
    props.dispatch(userLogoutStart());
    props.history.push("/");
  }, []);
  return "";
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapDispatchToProps
)(translate(Logout));
