export const FETCH_USER_DETAILS_START = "FETCH_USER_DETAILS_START";
export const FETCH_USER_DETAILS_SUCCESS = "FETCH_USER_DETAILS_SUCCESS";
export const FETCH_USER_DETAILS_FAILURE = "FETCH_USER_DETAILS_FAILURE";

export const EDIT_USER_DETAILS = "EDIT_USER_DETAILS";

export const UPDATE_USER_DETAILS_START = "UPDATE_USER_DETAILS_START";
export const UPDATE_USER_DETAILS_SUCCESS = "UPDATE_USER_DETAILS_SUCCESS";
export const UPDATE_USER_DETAILS_FAILURE = "UPDATE_USER_DETAILS_FAILURE";

export const EDIT_CHANGE_PASSWORD = "EDIT_CHANGE_PASSWORD";

export const CHANGE_PASSWORD_START = "CHANGE_PASSWORD_START";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

export const DELETE_ACCOUNT_START = "DELETE_ACCOUNT_START";
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_FAILURE = "DELETE_ACCOUNT_FAILURE";

export const FETCH_PAYMENTS_START = "FETCH_PAYMENTS_START";
export const FETCH_PAYMENTS_SUCCESS = "FETCH_PAYMENTS_SUCCESS";
export const FETCH_PAYMENTS_FAILURE = "FETCH_PAYMENTS_FAILURE";

export const GET_LOGIN_DETAILS = "GET_LOGIN_DETAILS";

export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const REGISTER_START = "REGISTER_START";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";

export const GET_REGISTER_DETAILS = "GET_REGISTER_DETAILS";

export const FORGOT_PASSWORD_START = "FORGOT_PASSWORD_START";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

export const GET_FORGOT_PASSWORD_DETAILS = "GET_FORGOT_PASSWORD_DETAILS";

// Subscription constants

export const FETCH_SUBSCRIPTION_START = "FETCH_SUBSCRIPTION_START";
export const FETCH_SUBSCRIPTION_SUCCESS = "FETCH_SUBSCRIPTION_SUCCESS";
export const FETCH_SUBSCRIPTION_FAILURE = "FETCH_SUBSCRIPTION_FAILURE";

export const FETCH_MY_SUBSCRIPTION_START = "FETCH_MY_SUBSCRIPTION_START";
export const FETCH_MY_SUBSCRIPTION_SUCCESS = "FETCH_MY_SUBSCRIPTION_SUCCESS";
export const FETCH_MY_SUBSCRIPTION_FAILURE = "FETCH_MY_SUBSCRIPTION_FAILURE";

export const FETCH_SINGLE_SUBSCRIPTION_START =
  "FETCH_SINGLE_SUBSCRIPTION_START";
export const FETCH_SINGLE_SUBSCRIPTION_SUCCESS =
  "FETCH_SINGLE_SUBSCRIPTION_SUCCESS";
export const FETCH_SINGLE_SUBSCRIPTION_FAILURE =
  "FETCH_SINGLE_SUBSCRIPTION_FAILURE";

export const SUBSCRIPTION_PAYMENT_STRIPE_START =
  "SUBSCRIPTION_PAYMENT_STRIPE_START";
export const SUBSCRIPTION_PAYMENT_STRIPE_SUCCESS =
  "SUBSCRIPTION_PAYMENT_STRIPE_SUCCESS";
export const SUBSCRIPTION_PAYMENT_STRIPE_FAILURE =
  "SUBSCRIPTION_PAYMENT_STRIPE_FAILURE";

export const SUBSCRIPTION_PAYMENT_WALLET_START =
  "SUBSCRIPTION_PAYMENT_WALLET_START";
export const SUBSCRIPTION_PAYMENT_WALLET_SUCCESS =
  "SUBSCRIPTION_PAYMENT_WALLET_SUCCESS";
export const SUBSCRIPTION_PAYMENT_WALLET_FAILURE =
  "SUBSCRIPTION_PAYMENT_WALLET_FAILURE";

export const SUBSCRIPTION_AUTO_RENEWAL_START =
  "SUBSCRIPTION_AUTO_RENEWAL_START";
export const SUBSCRIPTION_AUTO_RENEWAL_SUCCESS =
  "SUBSCRIPTION_AUTO_RENEWAL_SUCCESS";
export const SUBSCRIPTION_AUTO_RENEWAL_FAILURE =
  "SUBSCRIPTION_AUTO_RENEWAL_FAILURE";

// Cards constants

export const FETCH_CARD_DETAILS_START = "FETCH_CARD_DETAILS_START";
export const FETCH_CARD_DETAILS_SUCCESS = "FETCH_CARD_DETAILS_SUCCESS";
export const FETCH_CARD_DETAILS_FAILURE = "FETCH_CARD_DETAILS_FAILURE";

export const DELETE_CARD_START = "DELETE_CARD_START";
export const DELETE_CARD_SUCCESS = "DELETE_CARD_SUCCESS";
export const DELETE_CARD_FAILURE = "DELETE_CARD_FAILURE";

export const SELECT_DEFAULT_CARD_START = "SELECT_DEFAULT_CARD_START";
export const SELECT_DEFAULT_CARD_SUCCESS = "SELECT_DEFAULT_CARD_SUCCESS";
export const SELECT_DEFAULT_CARD_FAILURE = "SELECT_DEFAULT_CARD_FAILURE";

// Bank account

export const ADD_BANK_ACCOUNT_START = "ADD_BANK_ACCOUNT_START";
export const ADD_BANK_ACCOUNT_SUCCESS = "ADD_BANK_ACCOUNT_SUCCESS";
export const ADD_BANK_ACCOUNT_FAILURE = "ADD_BANK_ACCOUNT_FAILURE";

export const GET_BANK_ACCOUNT_START = "GET_BANK_ACCOUNT_START";
export const GET_BANK_ACCOUNT_SUCCESS = "GET_BANK_ACCOUNT_SUCCESS";
export const GET_BANK_ACCOUNT_FAILURE = "GET_BANK_ACCOUNT_FAILURE";

export const DELETE_BANK_ACCOUNT_START = "DELETE_BANK_ACCOUNT_START";
export const DELETE_BANK_ACCOUNT_SUCCESS = "DELETE_BANK_ACCOUNT_SUCCESS";
export const DELETE_BANK_ACCOUNT_FAILURE = "DELETE_BANK_ACCOUNT_FAILURE";

export const ADD_BANK_ACCOUNT_DATA = "ADD_BANK_ACCOUNT_DATA";

export const FETCH_SINGLE_BANK_ACCOUNT_START =
  "FETCH_SINGLE_BANK_ACCOUNT_START";
export const FETCH_SINGLE_BANK_ACCOUNT_SUCCESS =
  "FETCH_SINGLE_BANK_ACCOUNT_SUCCESS";
export const FETCH_SINGLE_BANK_ACCOUNT_FAILURE =
  "FETCH_SINGLE_BANK_ACCOUNT_FAILURE";

export const MAKE_DEFAULT_BANK_ACCOUNT_START =
  "MAKE_DEFAULT_BANK_ACCOUNT_START";
export const MAKE_DEFAULT_BANK_ACCOUNT_SUCCESS =
  "MAKE_DEFAULT_BANK_ACCOUNT_SUCCESS";
export const MAKE_DEFAULT_BANK_ACCOUNT_FAILURE =
  "MAKE_DEFAULT_BANK_ACCOUNT_FAILURE";

export const GET_KYC_DOCUMENT_START = "GET_KYC_DOCUMENT_START";
export const GET_KYC_DOCUMENT_SUCCESS = "GET_KYC_DOCUMENT_SUCCESS";
export const GET_KYC_DOCUMENT_FAILURE = "GET_KYC_DOCUMENT_FAILURE";

export const ADD_KYC_DOCUMENT_START = "ADD_KYC_DOCUMENT_START";
export const ADD_KYC_DOCUMENT_SUCCESS = "ADD_KYC_DOCUMENT_SUCCESS";
export const ADD_KYC_DOCUMENT_FAILURE = "ADD_KYC_DOCUMENT_FAILURE";

// Wallet constants

export const FETCH_WALLET_DETAILS_START = "FETCH_WALLET_DETAILS_START";
export const FETCH_WALLET_DETAILS_SUCCESS = "FETCH_WALLET_DETAILS_SUCCESS";
export const FETCH_WALLET_DETAILS_FAILURE = "FETCH_WALLET_DETAILS_FAILURE";

export const ADD_MONEY_VIA_CARD_START = "ADD_MONEY_VIA_CARD_START";
export const ADD_MONEY_VIA_CARD_SUCCESS = "ADD_MONEY_VIA_CARD_SUCCESS";
export const ADD_MONEY_VIA_CARD_FAILURE = "ADD_MONEY_VIA_CARD_FAILURE";

export const ADD_MONEY_VIA_BANK_START = "ADD_MONEY_VIA_BANK_START";
export const ADD_MONEY_VIA_BANK_SUCCESS = "ADD_MONEY_VIA_BANK_SUCCESS";
export const ADD_MONEY_VIA_BANK_FAILURE = "ADD_MONEY_VIA_BANK_FAILURE";

// Transaction constants.

export const FETCH_ALL_TRANSACTION_START = "FETCH_ALL_TRANSACTION_START";
export const FETCH_ALL_TRANSACTION_SUCCESS = "FETCH_ALL_TRANSACTION_SUCCESS";
export const FETCH_ALL_TRANSACTION_FAILURE = "FETCH_ALL_TRANSACTION_FAILURE";

export const FETCH_SENT_PAYMENT_TRANSACTION_START =
  "FETCH_SENT_PAYMENT_TRANSACTION_START";
export const FETCH_SENT_PAYMENT_TRANSACTION_SUCCESS =
  "FETCH_SENT_PAYMENT_TRANSACTION_SUCCESS";
export const FETCH_SENT_PAYMENT_TRANSACTION_FAILURE =
  "FETCH_SENT_PAYMENT_TRANSACTION_FAILURE";

export const FETCH_RECEIVED_PAYMENT_TRANSACTION_START =
  "FETCH_RECEIVED_PAYMENT_TRANSACTION_START";
export const FETCH_RECEIVED_PAYMENT_TRANSACTION_SUCCESS =
  "FETCH_RECEIVED_PAYMENT_TRANSACTION_SUCCESS";
export const FETCH_RECEIVED_PAYMENT_TRANSACTION_FAILURE =
  "FETCH_RECEIVED_PAYMENT_TRANSACTION_FAILURE";

export const FETCH_DEPOSIT_TRANSACTION_START =
  "FETCH_DEPOSIT_TRANSACTION_START";
export const FETCH_DEPOSIT_TRANSACTION_SUCCESS =
  "FETCH_DEPOSIT_TRANSACTION_SUCCESS";
export const FETCH_DEPOSIT_TRANSACTION_FAILURE =
  "FETCH_DEPOSIT_TRANSACTION_FAILURE";

// Invoice constants.

export const SAVE_BUSINESS_DETAILS_START = "SAVE_BUSINESS_DETAILS_START";
export const SAVE_BUSINESS_DETAILS_SUCCESS = "SAVE_BUSINESS_DETAILS_SUCCESS";
export const SAVE_BUSINESS_DETAILS_FAILURE = "SAVE_BUSINESS_DETAILS_FAILURE";

export const FETCH_BUSINESS_DETAILS_START = "FETCH_BUSINESS_DETAILS_START";
export const FETCH_BUSINESS_DETAILS_SUCCESS = "FETCH_BUSINESS_DETAILS_SUCCESS";
export const FETCH_BUSINESS_DETAILS_FAILURE = "FETCH_BUSINESS_DETAILS_FAILURE";

// Withdraw constants

export const SEND_WITHDRAW_REQUEST_START = "SEND_WITHDRAW_REQUEST_START";
export const SEND_WITHDRAW_REQUEST_SUCCESS = "SEND_WITHDRAW_REQUEST_SUCCESS";
export const SEND_WITHDRAW_REQUEST_FAILURE = "SEND_WITHDRAW_REQUEST_FAILURE";

export const FETCH_WITHDRAWALS_START = "FETCH_WITHDRAWALS_START";
export const FETCH_WITHDRAWALS_SUCCESS = "FETCH_WITHDRAWALS_SUCCESS";
export const FETCH_WITHDRAWALS_FAILURE = "FETCH_WITHDRAWALS_FAILURE";

export const CANCEL_WITHDRAW_REQUEST_START = "CANCEL_WITHDRAW_REQUEST_START";
export const CANCEL_WITHDRAW_REQUEST_SUCCESS =
  "CANCEL_WITHDRAW_REQUEST_SUCCESS";
export const CANCEL_WITHDRAW_REQUEST_FAILURE =
  "CANCEL_WITHDRAW_REQUEST_FAILURE";

export const SEARCH_WITHDRAWALS_START = "SEARCH_WITHDRAWALS_START";
export const SEARCH_WITHDRAWALS_SUCCESS = "SEARCH_WITHDRAWALS_SUCCESS";
export const SEARCH_WITHDRAWALS_FAILURE = "SEARCH_WITHDRAWALS_FAILURE";

export const FETCH_SINGLE_WITHDRAWALS_START = "FETCH_SINGLE_WITHDRAWALS_START";
export const FETCH_SINGLE_WITHDRAWALS_SUCCESS =
  "FETCH_SINGLE_WITHDRAWALS_SUCCESS";
export const FETCH_SINGLE_WITHDRAWALS_FAILURE =
  "FETCH_SINGLE_WITHDRAWALS_FAILURE";

// Dispute constants

export const SEND_DISPUTE_REQUEST_START = "SEND_DISPUTE_REQUEST_START";
export const SEND_DISPUTE_REQUEST_SUCCESS = "SEND_DISPUTE_REQUEST_SUCCESS";
export const SEND_DISPUTE_REQUEST_FAILURE = "SEND_DISPUTE_REQUEST_FAILURE";

export const FETCH_DISPUTES_START = "FETCH_DISPUTES_START";
export const FETCH_DISPUTES_SUCCESS = "FETCH_DISPUTES_SUCCESS";
export const FETCH_DISPUTES_FAILURE = "FETCH_DISPUTES_FAILURE";

export const FETCH_OPENED_CASE_START = "FETCH_OPENED_CASE_START";
export const FETCH_OPENED_CASE_SUCCESS = "FETCH_OPENED_CASE_SUCCESS";
export const FETCH_OPENED_CASE_FAILURE = "FETCH_OPENED_CASE_FAILURE";

export const FETCH_CLOSED_CASE_START = "FETCH_CLOSED_CASE_START";
export const FETCH_CLOSED_CASE_SUCCESS = "FETCH_CLOSED_CASE_SUCCESS";
export const FETCH_CLOSED_CASE_FAILURE = "FETCH_CLOSED_CASE_FAILURE";

export const GET_SINGLE_DISPUTE_MESSAGES_START =
  "GET_SINGLE_DISPUTE_MESSAGES_START";
export const GET_SINGLE_DISPUTE_MESSAGES_SUCCESS =
  "GET_SINGLE_DISPUTE_MESSAGES_SUCCESS";
export const GET_SINGLE_DISPUTE_MESSAGES_FAILURE =
  "GET_SINGLE_DISPUTE_MESSAGES_FAILURE";

export const SEND_DISPUTE_MESSAGE_START = "SEND_DISPUTE_MESSAGE_START";
export const SEND_DISPUTE_MESSAGE_SUCCESS = "SEND_DISPUTE_MESSAGE_SUCCESS";
export const SEND_DISPUTE_MESSAGE_FAILURE = "SEND_DISPUTE_MESSAGE_FAILURE";

export const CANCEL_DISPUTE_REQUEST_START = "CANCEL_DISPUTE_REQUEST_START";
export const CANCEL_DISPUTE_REQUEST_SUCCESS = "CANCEL_DISPUTE_REQUEST_SUCCESS";
export const CANCEL_DISPUTE_REQUEST_FAILURE = "CANCEL_DISPUTE_REQUEST_FAILURE";

export const FETCH_SINGLE_DISPUTE_START = "FETCH_SINGLE_DISPUTE_START";
export const FETCH_SINGLE_DISPUTE_SUCCESS = "FETCH_SINGLE_DISPUTE_SUCCESS";
export const FETCH_SINGLE_DISPUTE_FAILURE = "FETCH_SINGLE_DISPUTE_FAILURE";

// Registeration verify.

export const REGISTER_VERIFY_START = "REGISTER_VERIFY_START";
export const REGISTER_VERIFY_SUCCESS = "REGISTER_VERIFY_SUCCESS";
export const REGISTER_VERIFY_FAILURE = "REGISTER_VERIFY_FAILURE";

export const REGISTER_VERIFY_RESEND_START = "REGISTER_VERIFY_RESEND_START";
export const REGISTER_VERIFY_RESEND_SUCCESS = "REGISTER_VERIFY_RESEND_SUCCESS";
export const REGISTER_VERIFY_RESEND_FAILURE = "REGISTER_VERIFY_RESEND_FAILURE";

// Pages

export const FETCH_SINGLE_PAGE_START = "FETCH_SINGLE_PAGE_START";
export const FETCH_SINGLE_PAGE_SUCCESS = "FETCH_SINGLE_PAGE_SUCCESS";
export const FETCH_SINGLE_PAGE_FAILURE = "FETCH_SINGLE_PAGE_FAILURE";

// Notification on or off

export const NOTIFICATION_STATUS_UPDATE_START =
  "NOTIFICATION_STATUS_UPDATE_START";
export const NOTIFICATION_STATUS_UPDATE_SUCCESS =
  "NOTIFICATION_STATUS_UPDATE_SUCCESS";
export const NOTIFICATION_STATUS_UPDATE_FAILURE =
  "NOTIFICATION_STATUS_UPDATE_FAILURE";

// Error Handling for logout

export const ERROR_LOGOUT_CHECK = "ERROR_LOGOUT_CHECK";

// Product owner constant

export const SAVE_PRO_OWNER_START = "SAVE_PRO_OWNER_START";
export const SAVE_PRO_OWNER_SUCCESS = "SAVE_PRO_OWNER_SUCCESS";
export const SAVE_PRO_OWNER_FAILURE = "SAVE_PRO_OWNER_FAILURE";

export const FETCH_SINGLE_PRO_OWNER_START = "FETCH_SINGLE_PRO_OWNER_START";
export const FETCH_SINGLE_PRO_OWNER_SUCCESS = "FETCH_SINGLE_PRO_OWNER_SUCCESS";
export const FETCH_SINGLE_PRO_OWNER_FAILURE = "FETCH_SINGLE_PRO_OWNER_FAILURE";

export const SET_VISIBILITY_PRO_OWNER_START = "SET_VISIBILITY_PRO_OWNER_START";
export const SET_VISIBILITY_PRO_OWNER_SUCCESS =
  "SET_VISIBILITY_PRO_OWNER_SUCCESS";
export const SET_VISIBILITY_PRO_OWNER_FAILURE =
  "SET_VISIBILITY_PRO_OWNER_FAILURE";

export const UPDATE_AVAILABILITY_PRO_OWNER_START =
  "UPDATE_AVAILABILITY_PRO_OWNER_START";
export const UPDATE_AVAILABILITY_PRO_OWNER_SUCCESS =
  "UPDATE_AVAILABILITY_PRO_OWNER_SUCCESS";
export const UPDATE_AVAILABILITY_PRO_OWNER_FAILURE =
  "UPDATE_AVAILABILITY_PRO_OWNER_FAILURE";

export const FETCH_PRODUCTS_PRO_OWNER_START = "FETCH_PRODUCTS_PRO_OWNER_START";
export const FETCH_PRODUCTS_PRO_OWNER_SUCCESS =
  "FETCH_PRODUCTS_PRO_OWNER_SUCCESS";
export const FETCH_PRODUCTS_PRO_OWNER_FAILURE =
  "FETCH_PRODUCTS_PRO_OWNER_FAILURE";

export const FETCH_PRO_CATE_PRO_OWNER_START = "FETCH_PRO_CATE_PRO_OWNER_START";
export const FETCH_PRO_CATE_PRO_OWNER_SUCCESS =
  "FETCH_PRO_CATE_PRO_OWNER_SUCCESS";
export const FETCH_PRO_CATE_PRO_OWNER_FAILURE =
  "FETCH_PRO_CATE_PRO_OWNER_FAILURE";

export const FETCH_PRO_SUBCATE_PRO_OWNER_START =
  "FETCH_PRO_SUBCATE_PRO_OWNER_START";
export const FETCH_PRO_SUBCATE_PRO_OWNER_SUCCESS =
  "FETCH_PRO_SUBCATE_PRO_OWNER_SUCCESS";
export const FETCH_PRO_SUBCATE_PRO_OWNER_FAILURE =
  "FETCH_PRO_SUBCATE_PRO_OWNER_FAILURE";

export const PRO_SEARCH_PRO_OWNER_START = "PRO_SEARCH_PRO_OWNER_START";
export const PRO_SEARCH_PRO_OWNER_SUCCESS = "PRO_SEARCH_PRO_OWNER_SUCCESS";
export const PRO_SEARCH_PRO_OWNER_FAILURE = "PRO_SEARCH_PRO_OWNER_FAILURE";

export const FETCH_PRO_IMAGE_PRO_OWNER_START =
  "FETCH_PRO_IMAGE_PRO_OWNER_START";
export const FETCH_PRO_IMAGE_PRO_OWNER_SUCCESS =
  "FETCH_PRO_IMAGE_PRO_OWNER_SUCCESS";
export const FETCH_PRO_IMAGE_PRO_OWNER_FAILURE =
  "FETCH_PRO_IMAGE_PRO_OWNER_FAILURE";

export const SAVE_PRO_IMAGE_PRO_OWNER_START = "SAVE_PRO_IMAGE_PRO_OWNER_START";
export const SAVE_PRO_IMAGE_PRO_OWNER_SUCCESS =
  "SAVE_PRO_IMAGE_PRO_OWNER_SUCCESS";
export const SAVE_PRO_IMAGE_PRO_OWNER_FAILURE =
  "SAVE_PRO_IMAGE_PRO_OWNER_FAILURE";

export const DELETE_PRO_IMAGE_PRO_OWNER_START =
  "DELETE_PRO_IMAGE_PRO_OWNER_START";
export const DELETE_PRO_IMAGE_PRO_OWNER_SUCCESS =
  "DELETE_PRO_IMAGE_PRO_OWNER_SUCCESS";
export const DELETE_PRO_IMAGE_PRO_OWNER_FAILURE =
  "DELETE_PRO_IMAGE_PRO_OWNER_FAILURE";

// Post constants

export const SAVE_POST_START = "SAVE_POST_START";
export const SAVE_POST_SUCCESS = "SAVE_POST_SUCCESS";
export const SAVE_POST_FAILURE = "SAVE_POST_FAILURE";

export const FETCH_POSTS_START = "FETCH_POSTS_START";
export const FETCH_POSTS_SUCCESS = "FETCH_POSTS_SUCCESS";
export const FETCH_POSTS_FAILURE = "FETCH_POSTS_FAILURE";

export const FETCH_SINGLE_POST_START = "FETCH_SINGLE_POST_START";
export const FETCH_SINGLE_POST_SUCCESS = "FETCH_SINGLE_POST_SUCCESS";
export const FETCH_SINGLE_POST_FAILURE = "FETCH_SINGLE_POST_FAILURE";

export const DELETE_POST_START = "DELETE_POST_START";
export const DELETE_POST_SUCCESS = "DELETE_POST_SUCCESS";
export const DELETE_POST_FAILURE = "DELETE_POST_FAILURE";

export const CHANGE_POST_STATUS_START = "CHANGE_POST_STATUS_START";
export const CHANGE_POST_STATUS_SUCCESS = "CHANGE_POST_STATUS_SUCCESS";
export const CHANGE_POST_STATUS_FAILURE = "CHANGE_POST_STATUS_FAILURE";

export const POST_FILE_UPLOAD_START = "POST_FILE_UPLOAD_START";
export const POST_FILE_UPLOAD_SUCCESS = "POST_FILE_UPLOAD_SUCCESS";
export const POST_FILE_UPLOAD_FAILURE = "POST_FILE_UPLOAD_FAILURE";

export const POST_FILE_REMOVE_START = "POST_FILE_REMOVE_START";
export const POST_FILE_REMOVE_SUCCESS = "POST_FILE_REMOVE_SUCCESS";
export const POST_FILE_REMOVE_FAILURE = "POST_FILE_REMOVE_FAILURE";

export const VIEW_POST_VIDEO_START = "VIEW_POST_VIDEO_START";
export const VIEW_POST_VIDEO_SUCCESS = "VIEW_POST_VIDEO_SUCCESS";
export const VIEW_POST_VIDEO_FAILURE = "VIEW_POST_VIDEO_FAILURE";

// Follow constants

export const FOLLOW_USER_START = "FOLLOW_USER_START";
export const FOLLOW_USER_SUCCESS = "FOLLOW_USER_SUCCESS";
export const FOLLOW_USER_FAILURE = "FOLLOW_USER_FAILURE";

export const UNFOLLOW_USER_START = "UNFOLLOW_USER_START";
export const UNFOLLOW_USER_SUCCESS = "UNFOLLOW_USER_SUCCESS";
export const UNFOLLOW_USER_FAILURE = "UNFOLLOW_USER_FAILURE";

export const FETCH_FOLLOWERS_START = "FETCH_FOLLOWERS_START";
export const FETCH_FOLLOWERS_SUCCESS = "FETCH_FOLLOWERS_SUCCESS";
export const FETCH_FOLLOWERS_FAILURE = "FETCH_FOLLOWERS_FAILURE";

export const FETCH_BLOCKED_USER_START = "FETCH_BLOCKED_USER_START";
export const FETCH_BLOCKED_USER_SUCCESS = "FETCH_BLOCKED_USER_SUCCESS";
export const FETCH_BLOCKED_USER_FAILURE = "FETCH_BLOCKED_USER_FAILURE";

export const FETCH_ACTIVE_FOLLOWERS_START = "FETCH_ACTIVE_FOLLOWERS_START";
export const FETCH_ACTIVE_FOLLOWERS_SUCCESS = "FETCH_ACTIVE_FOLLOWERS_SUCCESS";
export const FETCH_ACTIVE_FOLLOWERS_FAILURE = "FETCH_ACTIVE_FOLLOWERS_FAILURE";

export const FETCH_EXPIRED_FOLLOWERS_START = "FETCH_EXPIRED_FOLLOWERS_START";
export const FETCH_EXPIRED_FOLLOWERS_SUCCESS =
  "FETCH_EXPIRED_FOLLOWERS_SUCCESS";
export const FETCH_EXPIRED_FOLLOWERS_FAILURE =
  "FETCH_EXPIRED_FOLLOWERS_FAILURE";

export const FETCH_FOLLOWING_START = "FETCH_FOLLOWING_START";
export const FETCH_FOLLOWING_SUCCESS = "FETCH_FOLLOWING_SUCCESS";
export const FETCH_FOLLOWING_FAILURE = "FETCH_FOLLOWING_FAILURE";

export const FETCH_ACTIVE_FOLLOWING_START = "FETCH_ACTIVE_FOLLOWING_START";
export const FETCH_ACTIVE_FOLLOWING_SUCCESS = "FETCH_ACTIVE_FOLLOWING_SUCCESS";
export const FETCH_ACTIVE_FOLLOWING_FAILURE = "FETCH_ACTIVE_FOLLOWING_FAILURE";

export const FETCH_EXPIRED_FOLLOWING_START = "FETCH_EXPIRED_FOLLOWING_START";
export const FETCH_EXPIRED_FOLLOWING_SUCCESS =
  "FETCH_EXPIRED_FOLLOWING_SUCCESS";
export const FETCH_EXPIRED_FOLLOWING_FAILURE =
  "FETCH_EXPIRED_FOLLOWING_FAILURE";

// Document verification

export const FETCH_VERI_DOCUMENT_START = "FETCH_VERI_DOCUMENT_START";
export const FETCH_VERI_DOCUMENT_SUCCESS = "FETCH_VERI_DOCUMENT_SUCCESS";
export const FETCH_VERI_DOCUMENT_FAILURE = "FETCH_VERI_DOCUMENT_FAILURE";

export const SAVE_VERI_DOC_START = "SAVE_VERI_DOC_START";
export const SAVE_VERI_DOC_SUCCESS = "SAVE_VERI_DOC_SUCCESS";
export const SAVE_VERI_DOC_FAILURE = "SAVE_VERI_DOC_FAILURE";

export const DEL_VERI_DOC_START = "DEL_VERI_DOC_START";
export const DEL_VERI_DOC_SUCCESS = "DEL_VERI_DOC_SUCCESS";
export const DEL_VERI_DOC_FAILURE = "DEL_VERI_DOC_FAILURE";

export const VERI_STATUS_CHECK_START = "VERI_STATUS_CHECK_START";
export const VERI_STATUS_CHECK_SUCCESS = "VERI_STATUS_CHECK_SUCCESS";
export const VERI_STATUS_CHECK_FAILURE = "VERI_STATUS_CHECK_FAILURE";

// Comments constant

export const FETCH_COMMENTS_START = "FETCH_COMMENTS_START";
export const FETCH_COMMENTS_SUCCESS = "FETCH_COMMENTS_SUCCESS";
export const FETCH_COMMENTS_FAILURE = "FETCH_COMMENTS_FAILURE";

export const SAVE_COMMENT_START = "SAVE_COMMENT_START";
export const SAVE_COMMENT_SUCCESS = "SAVE_COMMENT_SUCCESS";
export const SAVE_COMMENT_FAILURE = "SAVE_COMMENT_FAILURE";

export const DELETE_COMMENT_START = "DELETE_COMMENT_START";
export const DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS";
export const DELETE_COMMENT_FAILURE = "DELETE_COMMENT_FAILURE";

// Fav constant

export const FETCH_FAV_START = "FETCH_FAV_START";
export const FETCH_FAV_SUCCESS = "FETCH_FAV_SUCCESS";
export const FETCH_FAV_FAILURE = "FETCH_FAV_FAILURE";

export const SAVE_FAV_START = "SAVE_FAV_START";
export const SAVE_FAV_SUCCESS = "SAVE_FAV_SUCCESS";
export const SAVE_FAV_FAILURE = "SAVE_FAV_FAILURE";

export const DELETE_FAV_START = "DELETE_FAV_START";
export const DELETE_FAV_SUCCESS = "DELETE_FAV_SUCCESS";
export const DELETE_FAV_FAILURE = "DELETE_FAV_FAILURE";

// Bookmark Management constant

export const FETCH_BOOKMARKS_START = "FETCH_BOOKMARKS_START";
export const FETCH_BOOKMARKS_SUCCESS = "FETCH_BOOKMARKS_SUCCESS";
export const FETCH_BOOKMARKS_FAILURE = "FETCH_BOOKMARKS_FAILURE";

export const FETCH_BOOKMARKS_PHOTO_START = "FETCH_BOOKMARKS_PHOTO_START";
export const FETCH_BOOKMARKS_PHOTO_SUCCESS = "FETCH_BOOKMARKS_PHOTO_SUCCESS";
export const FETCH_BOOKMARKS_PHOTO_FAILURE = "FETCH_BOOKMARKS_PHOTO_FAILURE";

export const FETCH_BOOKMARKS_VIDEO_START = "FETCH_BOOKMARKS_VIDEO_START";
export const FETCH_BOOKMARKS_VIDEO_SUCCESS = "FETCH_BOOKMARKS_VIDEO_SUCCESS";
export const FETCH_BOOKMARKS_VIDEO_FAILURE = "FETCH_BOOKMARKS_VIDEO_FAILURE";

export const SAVE_BOOKMARK_START = "SAVE_BOOKMARK_START";
export const SAVE_BOOKMARK_SUCCESS = "SAVE_BOOKMARK_SUCCESS";
export const SAVE_BOOKMARK_FAILURE = "SAVE_BOOKMARK_FAILURE";

export const DELETE_BOOKMARK_START = "DELETE_BOOKMARK_START";
export const DELETE_BOOKMARK_SUCCESS = "DELETE_BOOKMARK_SUCCESS";
export const DELETE_BOOKMARK_FAILURE = "DELETE_BOOKMARK_FAILURE";

// Send Tip constant

export const SEND_TIP_BY_STRIPE_START = "SEND_TIP_BY_STRIPE_START";
export const SEND_TIP_BY_STRIPE_SUCCESS = "SEND_TIP_BY_STRIPE_SUCCESS";
export const SEND_TIP_BY_STRIPE_FAILURE = "SEND_TIP_BY_STRIPE_FAILURE";

export const SEND_TIP_BY_WALLET_START = "SEND_TIP_BY_WALLET_START";
export const SEND_TIP_BY_WALLET_SUCCESS = "SEND_TIP_BY_WALLET_SUCCESS";
export const SEND_TIP_BY_WALLET_FAILURE = "SEND_TIP_BY_WALLET_FAILURE";

// Home constants

export const FETCH_HOME_POSTS_START = "FETCH_HOME_POSTS_START";
export const FETCH_HOME_POSTS_SUCCESS = "FETCH_HOME_POSTS_SUCCESS";
export const FETCH_HOME_POSTS_FAILURE = "FETCH_HOME_POSTS_FAILURE";

export const SEARCH_USER_START = "SEARCH_USER_START";
export const SEARCH_USER_SUCCESS = "SEARCH_USER_SUCCESS";
export const SEARCH_USER_FAILURE = "SEARCH_USER_FAILURE";

export const FETCH_OTHERS_SINGLE_POST_START = "FETCH_OTHERS_SINGLE_POST_START";
export const FETCH_OTHERS_SINGLE_POST_SUCCESS =
  "FETCH_OTHERS_SINGLE_POST_SUCCESS";
export const FETCH_OTHERS_SINGLE_POST_FAILURE =
  "FETCH_OTHERS_SINGLE_POST_FAILURE";

export const FETCH_POST_SUGGESTION_START = "FETCH_POST_SUGGESTION_START";
export const FETCH_POST_SUGGESTION_SUCCESS = "FETCH_POST_SUGGESTION_SUCCESS";
export const FETCH_POST_SUGGESTION_FAILURE = "FETCH_POST_SUGGESTION_FAILURE";

export const POST_PAYMENT_STRIPE_START = "POST_PAYMENT_STRIPE_START";
export const POST_PAYMENT_STRIPE_SUCCESS = "POST_PAYMENT_STRIPE_SUCCESS";
export const POST_PAYMENT_STRIPE_FAILURE = "POST_PAYMENT_STRIPE_FAILURE";

export const POST_PAYMENT_WALLET_START = "POST_PAYMENT_WALLET_START";
export const POST_PAYMENT_WALLET_SUCCESS = "POST_PAYMENT_WALLET_SUCCESS";
export const POST_PAYMENT_WALLET_FAILURE = "POST_PAYMENT_WALLET_FAILURE";

export const FETCH_LISTS_DETAILS_START = "FETCH_LISTS_DETAILS_START";
export const FETCH_LISTS_DETAILS_SUCCESS = "FETCH_LISTS_DETAILS_SUCCESS";
export const FETCH_LISTS_DETAILS_FAILURE = "FETCH_LISTS_DETAILS_FAILURE";

/// Model single profile and post

export const FETCH_SINGLE_USER_PROFILE_START =
  "FETCH_SINGLE_USER_PROFILE_START";
export const FETCH_SINGLE_USER_PROFILE_SUCCESS =
  "FETCH_SINGLE_USER_PROFILE_SUCCESS";
export const FETCH_SINGLE_USER_PROFILE_FAILURE =
  "FETCH_SINGLE_USER_PROFILE_FAILURE";

//livestream
export const FETCH_SINGLE_USER_STREAM_PROFILE_START =
  "FETCH_SINGLE_USER_STREAM_PROFILE_START";
export const FETCH_SINGLE_USER_STREAM_PROFILE_SUCC =
  "FETCH_SINGLE_USER_STREAM_PROFILE_SUCC";

  
export const DELETE_STREAM_CHAT_START = "DELETE_STREAM_CHAT_START";
export const DELETE_STREAM_CHAT_SUCCESS = "DELETE_STREAM_CHAT_SUCCESS";
export const DELETE_STREAM_CHAT_FAIL = "DELETE_STREAM_CHAT_FAIL";


export const FETCH_SINGLE_USER_POSTS_START = "FETCH_SINGLE_USER_POSTS_START";
export const FETCH_SINGLE_USER_POSTS_SUCCESS =
  "FETCH_SINGLE_USER_POSTS_SUCCESS";
export const FETCH_SINGLE_USER_POSTS_FAILURE =
  "FETCH_SINGLE_USER_POSTS_FAILURE";

export const SAVE_POST_LIKE_START = "SAVE_POST_LIKE_START";
export const SAVE_POST_LIKE_SUCCESS = "SAVE_POST_LIKE_SUCCESS";
export const SAVE_POST_LIKE_FAILURE = "SAVE_POST_LIKE_FAILURE";

export const FETCH_POST_LIKED_START = "FETCH_POST_LIKED_START";
export const FETCH_POST_LIKED_SUCCESS = "FETCH_POST_LIKED_SUCCESS";
export const FETCH_POST_LIKED_FAILURE = "FETCH_POST_LIKED_FAILURE";

// Chat constant

export const FETCH_CHAT_USERS_START = "FETCH_CHAT_USERS_START";
export const FETCH_CHAT_USERS_SUCCESS = "FETCH_CHAT_USERS_SUCCESS";
export const FETCH_CHAT_USERS_FAILURE = "FETCH_CHAT_USERS_FAILURE";

export const FETCH_STREAM_CHAT_USERS_START = "FETCH_STREAM_CHAT_USERS_START";
export const FETCH_STREAM_CHAT_USERS_SUCCESS =
  "FETCH_STREAM_CHAT_USERS_SUCCESS";
export const FETCH_STREAM_CHAT_USERS_FAILURE =
  "FETCH_STREAM_CHAT_USERS_FAILURE";

export const ADD_MESSAGE_CONTENT = "ADD_MESSAGE_CONTENT";
export const ADD_STREAM_MESSAGE_CONTENT = "ADD_STREAM_MESSAGE_CONTENT";

export const FETCH_CHAT_MESSAGE_START = "FETCH_CHAT_MESSAGE_START";
export const FETCH_CHAT_MESSAGE_SUCCESS = "FETCH_CHAT_MESSAGE_SUCCESS";
export const FETCH_CHAT_MESSAGE_FAILURE = "FETCH_CHAT_MESSAGE_FAILURE";

export const SAVE_CHAT_USERS_START = "SAVE_CHAT_USERS_START";
export const SAVE_CHAT_USERS_SUCCESS = "SAVE_CHAT_USERS_SUCCESS";
export const SAVE_CHAT_USERS_FAILURE = "SAVE_CHAT_USERS_FAILURE";

// Notification constant

export const FETCH_NOTIFICATIONS_START = "FETCH_NOTIFICATIONS_START";
export const FETCH_NOTIFICATIONS_SUCCESS = "FETCH_NOTIFICATIONS_SUCCESS";
export const FETCH_NOTIFICATIONS_FAILURE = "FETCH_NOTIFICATIONS_FAILURE";

export const PPV_PAYMENT_STRIPE_START = "PPV_PAYMENT_STRIPE_START";
export const PPV_PAYMENT_STRIPE_SUCCESS = "PPV_PAYMENT_STRIPE_SUCCESS";
export const PPV_PAYMENT_STRIPE_FAILURE = "PPV_PAYMENT_STRIPE_FAILURE";

export const PPV_PAYMENT_WALLET_START = "PPV_PAYMENT_WALLET_START";
export const PPV_PAYMENT_WALLET_SUCCESS = "PPV_PAYMENT_WALLET_SUCCESS";
export const PPV_PAYMENT_WALLET_FAILURE = "PPV_PAYMENT_WALLET_FAILURE";

export const SAVE_BLOCK_USER_START = "SAVE_BLOCK_USER_START";
export const SAVE_BLOCK_USER_SUCCESS = "SAVE_BLOCK_USER_SUCCESS";
export const SAVE_BLOCK_USER_FAILURE = "SAVE_BLOCK_USER_FAILURE";

export const FETCH_BLOCK_USERS_START = "FETCH_BLOCK_USERS_START";
export const FETCH_BLOCK_USERS_SUCCESS = "FETCH_BLOCK_USERS_SUCCESS";
export const FETCH_BLOCK_USERS_FAILURE = "FETCH_BLOCK_USERS_FAILURE";

export const SAVE_REPORT_POST_START = "SAVE_REPORT_POST_START";
export const SAVE_REPORT_POST_SUCCESS = "SAVE_REPORT_POST_SUCCESS";
export const SAVE_REPORT_POST_FAILURE = "SAVE_REPORT_POST_FAILURE";

export const FETCH_REPORT_POSTS_START = "FETCH_REPORT_POSTS_START";
export const FETCH_REPORT_POSTS_SUCCESS = "FETCH_REPORT_POSTS_SUCCESS";
export const FETCH_REPORT_POSTS_FAILURE = "FETCH_REPORT_POSTS_FAILURE";

export const SUBSCRIPTION_PAYMENT_PAYPAL_START =
  "SUBSCRIPTION_PAYMENT_PAYPAL_START";
export const SUBSCRIPTION_PAYMENT_PAYPAL_SUCCESS =
  "SUBSCRIPTION_PAYMENT_PAYPAL_SUCCESS";
export const SUBSCRIPTION_PAYMENT_PAYPAL_FAILURE =
  "SUBSCRIPTION_PAYMENT_PAYPAL_FAILURE";

export const PPV_PAYMENT_PAYPAL_START = "PPV_PAYMENT_PAYPAL_START";
export const PPV_PAYMENT_PAYPAL_SUCCESS = "PPV_PAYMENT_PAYPAL_SUCCESS";
export const PPV_PAYMENT_PAYPAL_FAILURE = "PPV_PAYMENT_PAYPAL_FAILURE";

export const SEND_TIP_BY_PAYPAL_START = "SEND_TIP_BY_PAYPAL_START";
export const SEND_TIP_BY_PAYPAL_SUCCESS = "SEND_TIP_BY_PAYPAL_SUCCESS";
export const SEND_TIP_BY_PAYPAL_FAILURE = "SEND_TIP_BY_PAYPAL_FAILURE";

export const USER_VERIFY_BADGE_STATUS_START = "USER_VERIFY_BADGE_STATUS_START";
export const USER_VERIFY_BADGE_STATUS_SUCCESS =
  "USER_VERIFY_BADGE_STATUS_SUCCESS";
export const USER_VERIFY_BADGE_STATUS_FAILURE =
  "USER_VERIFY_BADGE_STATUS_FAILURE";
// Reset Password
export const RESET_PASSWORD_START = "RESET_PASSWORD_START";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const USERNAME_VALIDATION_START = "USERNAME_VALIDATION_START";
export const USERNAME_VALIDATION_SUCCESS = "USERNAME_VALIDATION_SUCCESS";
export const USERNAME_VALIDATION_FAILURE = "USERNAME_VALIDATION_FAILURE";
export const USERNAME_VALIDATION_EMPTY = "USERNAME_VALIDATION_EMPTY";

export const SEARCH_USER_POST_START = "SEARCH_USER_POST_START";
export const SEARCH_USER_POST_SUCCESS = "SEARCH_USER_POST_SUCCESS";
export const SEARCH_USER_POST_FAILURE = "SEARCH_USER_POST_FAILURE";

export const LOGOUT_START = "LOGOUT_START";

//Live stream
export const SAVE_LIVESTREAM_START = "SAVE_LIVESTREAM_START";
export const SAVE_LIVESTREAM_SUCCESS = "SAVE_LIVESTREAM_SUCCESS";
export const SAVE_LIVESTREAM_FAILURE = "SAVE_LIVESTREAM_FAILURE";

export const FETCH_SINGLE_STREAM_START = "FETCH_SINGLE_STREAM_START";
export const FETCH_SINGLE_STREAM_FAILURE = "FETCH_SINGLE_STREAM_FAILURE";
export const FETCH_SINGLE_STREAM_SUCCESS = "FETCH_SINGLE_STREAM_SUCCESS";

export const FETCH_LIST_STREAM_START = "FETCH_LIST_STREAM_START";
export const FETCH_LIST_STREAM_FAILURE = "FETCH_LIST_STREAM_FAILURE";
export const FETCH_LIST_STREAM_SUCCESS = "FETCH_LIST_STREAM_SUCCESS";

export const SAVE_LIVESTREAM_USERS_START = "SAVE_LIVESTREAM_USERS_START";
export const SAVE_LIVESTREAM_USERS_SUCCESS = "SAVE_LIVESTREAM_USERS_SUCCESS";
export const SAVE_LIVESTREAM_USERS_FAILURE = "SAVE_LIVESTREAM_USERS_FAILURE";

export const LEAVE_LIVESTREAM_USER_START = "LEAVE_LIVESTREAM_USER_START";
export const LEAVE_LIVESTREAM_USER_SUCCESS = "LEAVE_LIVESTREAM_USER_SUCCESS";
export const LEAVE_LIVESTREAM_USER_FAILURE = "LEAVE_LIVESTREAM_USER_FAILURE";

export const GET_LIVE_STREAM_USERS = "GET_LIVE_STREAM_USERS";
export const GET_LIVE_STREAM_FAILURE = "GET_LIVE_STREAM_FAILURE";
export const GET_LIVE_STREAM_SUCCESS = "GET_LIVE_STREAM_SUCCESS";

//call
export const FETCH_SINGLE_CALL_START = "FETCH_SINGLE_CALL_START";
export const FETCH_SINGLE_CALL_FAILURE = "FETCH_SINGLE_CALL_FAILURE";
export const FETCH_SINGLE_CALL_SUCCESS = "FETCH_SINGLE_CALL_SUCCESS";

export const SAVE_SINGLE_CALL_START = "SAVE_SINGLE_CALL_START";
export const SAVE_SINGLE_CALL_FAILURE = "SAVE_SINGLE_CALL_FAILURE";
export const SAVE_SINGLE_CALL_SUCCESS = "SAVE_SINGLE_CALL_SUCCESS";

export const LEAVE_CALL_USER_START = "LEAVE_CALL_USER_START";
export const LEAVE_CALL_USER_SUCCESS = "LEAVE_CALL_USER_SUCCESS";
export const LEAVE_CALL_USER_FAILURE = "LEAVE_CALL_USER_FAILURE";

export const FETCH_LIST_VIDEO_START = "FETCH_LIST_VIDEO_START";
export const FETCH_LIST_VIDEO_FAILURE = "FETCH_LIST_VIDEO_FAILURE";
export const FETCH_LIST_VIDEO_SUCCESS = "FETCH_LIST_VIDEO_SUCCESS";

export const UPDATE_VIDEO_START = "UPDATE_VIDEO_START";
export const UPDATE_VIDEO_FAILURE = "UPDATE_VIDEO_FAILURE";
export const UPDATE_VIDEO_SUCCESS = "UPDATE_VIDEO_SUCCESS";

export const STREAM_PAYMENT_START = "STREAM_PAYMENT_START";
export const STREAM_PAYMENT_FAILURE = "STREAM_PAYMENT_FAILURE";
export const STREAM_PAYMENT_SUCCESS = "STREAM_PAYMENT_SUCCESS";
