import React, { createContext, useEffect, useState } from "react";
import configuration from "react-global-configuration";
import io from "socket.io-client";

export const SocketContext = createContext({
  chatSocket: {},
  liveStreamSocket: {},
  setChatSocket: (values) => { },
  chatSocketUrl: "",
  liveStreamSocketUrl: "",
  setChatSocketUrl: (values) => { },
  setLiveStreamSocketUrl: (values) => { },
});

const SocketProvider = ({ children }) => {
  const [chatSocketUrl, setChatSocketUrl] = useState("");
  const [liveStreamSocketUrl, setLiveStreamSocketUrl] = useState("");
  const [chatSocket, setChatSocket] = useState({});
  const [liveStreamSocket, setLiveStreamSocket] = useState({});

  const userId = Number(localStorage.getItem("user_id"));

  useEffect(() => {
    if (liveStreamSocketUrl && liveStreamSocketUrl != "" && Object.keys(liveStreamSocket).length == 0) {
      console.log("user connect 2")
      setLiveStreamSocket(
        io(liveStreamSocketUrl, {
          query:
            `commonid:'user_id_` +
            localStorage.getItem("userId") +
            `',myid:` +
            localStorage.getItem("userId"),
        })
      );
    }
  }, [liveStreamSocketUrl]);

  useEffect(() => {
    if (chatSocketUrl && chatSocketUrl != "" && Object.keys(chatSocket).length == 0) {
      console.log("user connect 1")
      setChatSocket(
        io(chatSocketUrl, {
          query:
            `commonid:'user_id_` +
            localStorage.getItem("userId") +
            `',myid:` +
            localStorage.getItem("userId"),
        })
      );
    }
  }, [chatSocketUrl]);
  
  const contextProvider = {
    chatSocket,
    setChatSocket,
    liveStreamSocket,
    chatSocketUrl,
    setChatSocketUrl,
    setLiveStreamSocket,
    liveStreamSocketUrl,
    setLiveStreamSocketUrl
  };

  return (
    <SocketContext.Provider value={contextProvider}>
      {children}
    </SocketContext.Provider>
  );
};

export const SocketState = () => {
  return React.useContext(SocketContext);
};

export default SocketProvider;
