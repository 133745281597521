import React, { useEffect } from "react";
import { merge } from "lodash";
import AgoraRTC from "agora-rtc-sdk-ng";
import { Container, Image, Media, Button, Badge } from "react-bootstrap";
import "./canvas.css";
import configuration from "react-global-configuration";
import { th } from "date-fns/locale";
import { translate, t } from "react-multi-lang";
import SendTipModal from "../../helper/SendTipModal";
import { isDesktop } from "react-device-detect";
import { useDispatch } from 'react-redux';
import {
  leaveLiveStreamUserStart,
} from "../../../store/actions/PostAction";
import {
  deleteStreamChatStart
} from "../../../store/actions/Livestream";


const tile_canvas = {
  1: ["span 12/span 24"],
  2: ["span 12/span 12/13/25", "span 12/span 12/13/13"],
  3: ["span 6/span 12", "span 6/span 12", "span 6/span 12/7/19"],
  4: [
    "span 6/span 12",
    "span 6/span 12",
    "span 6/span 12",
    "span 6/span 12/7/13",
  ],
  5: [
    "span 3/span 4/13/9",
    "span 3/span 4/13/13",
    "span 3/span 4/13/17",
    "span 3/span 4/13/21",
    "span 9/span 16/10/21",
  ],
  6: [
    "span 3/span 4/13/7",
    "span 3/span 4/13/11",
    "span 3/span 4/13/15",
    "span 3/span 4/13/19",
    "span 3/span 4/13/23",
    "span 9/span 16/10/21",
  ],
  7: [
    "span 3/span 4/13/5",
    "span 3/span 4/13/9",
    "span 3/span 4/13/13",
    "span 3/span 4/13/17",
    "span 3/span 4/13/21",
    "span 3/span 4/13/25",
    "span 9/span 16/10/21",
  ],
};

/**
 * @prop appId uid
 * @prop transcode attendeeMode videoProfile channel baseMode
 */
class AgoraCanvas extends React.Component {
  constructor(props) {
    // console.log(props);
    super(props);
    this.client = {};
    this.localStream = {};
    this.shareClient = {};
    this.shareStream = {};
    this.remoteUsers = {};
    this.state = {
      displayMode: "pip",
      streamList: [],
      readyState: false,
      stateSharing: false,
      sendTip: false,
    };
  }

  async componentWillMount() {
    let $ = this.props;
    // init AgoraRTC local client
    this.client = AgoraRTC.createClient({
      mode: $.attendeeMode,
      codec: $.codec,
    });

    this.subscribeStreamEvents();

    let uid = await this.client.join($.appId, $.channel, $.token, $.uid);

    if (uid) {
      console.log("aaaaaaaaaaaaaaa", uid);
      this.state.uid = uid;
      // this.props.user_update(uid);
      this.props.user_join(uid);
      if (this.props.host_id == uid) {
        // create local stream
        // It is not recommended to setState in function addStream
        this.localStream = await this.streamInit(
          uid,
          $.attendeeMode,
          $.videoProfile
        );

        if ($.attendeeMode !== "audience") {
          this.addStream(this.localStream, true);

          try {
            // Remove this line if the channel profile is not live broadcast.
            await this.client.setClientRole("host");
            await this.client.publish([
              this.localStream.localAudio,
              this.localStream.localVideo,
            ]);
            console.log("publish success");
          } catch (e) {
            console.log("publish failed", e);
          }
        }
        this.setState({ readyState: true });
      } else {
        this.setState({ readyState: true });
      }
    }
  }

  componentDidMount() {
    // add listener to control btn group
    let canvas = document.querySelector("#ag-canvas");
    let btnGroup = document.querySelector(".ag-btn-group");
    canvas.addEventListener("mousemove", () => {
      if (global._toolbarToggle) {
        clearTimeout(global._toolbarToggle);
      }
      btnGroup.classList.add("active");
      global._toolbarToggle = setTimeout(function () {
        btnGroup.classList.remove("active");
      }, 2000);
    });
  }

  componentDidUpdate() {
    // rerendering
    let canvas = document.querySelector("#ag-canvas");
    // pip mode (can only use when less than 4 people in channel)

    if (this.state.displayMode === "pip") {
      let no = this.state.streamList.length;
      if (no > 4) {
        // this.setState({ displayMode: "tile" });
        // return;
      }
      if (no != 0) {
        let ids = "";
        this.state.streamList.map((item, index) => {
          ids += this.state.uid + ",";

          if (this.props.host_id == this.state.uid) {
            let dom = document.querySelector("#agitem-" + this.props.uid);
            if (!dom) {
              dom = document.createElement("section");
              dom.setAttribute("id", "agitem-" + this.props.uid);
              dom.setAttribute("class", "agitem");
              canvas.appendChild(dom);
              item.localVideo.play("agitem-" + this.props.uid);
            }

            if (isDesktop) {
              dom.setAttribute("style", `grid-area: span 12/span 24/13/25`);
            }
            else {
              dom.setAttribute("style", `grid-area: span 25/span 24/11/25`);
            }

            //item.localVideo.resize();
          }
        });
        this.props.user_update(ids);
      }
    }
    // tile mode
    else if (this.state.displayMode === "tile") {
      let no = this.state.streamList.length;
      this.state.streamList.map((item, index) => {
        let id = this.props.uid;
        if (this.props.host_id == id) {
          let dom = document.querySelector("#agitem-" + id);
          if (!dom) {
            dom = document.createElement("section");
            dom.setAttribute("id", "agitem-" + id);
            dom.setAttribute("class", "agitem");
            canvas.appendChild(dom);
            item.localVideo.play("agitem-" + id);
          }
          dom.setAttribute("style", `grid-area: ${tile_canvas[no][index]}`);
          //item.player.resize && item.player.resize();
        }
      });
    }
    // screen share mode (tbd)
    else if (this.state.displayMode === "share") {
    }
  }

  componentWillUnmount() {
    this.client && this.localStream && 
      this.client.unpublish([
        this.localStream.localAudio,
        this.localStream.localVideo,
      ]);

    if (this.localStream != null && Object.keys(this.localStream).length != 0) {
      this.localStream && this.localStream.localVideo.close();
    }
    if (this.state.stateSharing) {
      this.shareClient && this.shareClient.unpublish(this.shareStream);
      this.shareStream && this.shareStream.close();
    }

    this.client &&
      this.client.leave(
        () => {
          console.log("Client succeed to leave.");
        },
        () => {
          console.log("Client failed to leave.");
        }
      );
    this.props.user_leave(localStorage.getItem("userId"));
  }

  streamInit = async (uid, attendeeMode, videoProfile, config) => {
    let defaultConfig = {
      streamID: uid,
      audio: true,
      video: true,
      screen: false,
    };

    switch (attendeeMode) {
      case "audio-only":
        defaultConfig.video = false;
        break;
      case "audience":
        defaultConfig.video = false;
        defaultConfig.audio = false;
        break;
      default:
      case "video":
        break;
    }

    const localAudio = await AgoraRTC.createMicrophoneAudioTrack();
    const localVideo = await AgoraRTC.createCameraVideoTrack();
    localVideo.play("agitem-" + uid);
    return { localVideo, localAudio };
  };

  subscribeStreamEvents = async () => {
    let rt = this;

    rt.client.on("user-published", async (remoteUser, mediaType) => {
      await rt.client.setClientRole("audience");
      await rt.client.subscribe(remoteUser, mediaType);

      if (mediaType === "video") {
        let dom = document.querySelector("#agitem-" + remoteUser.uid);
        if (!dom) {
          dom = document.createElement("section");
          dom.setAttribute("id", "agitem-" + remoteUser.uid);
          dom.setAttribute("class", "agitem");
          document.querySelector("#ag-canvas").appendChild(dom);
          remoteUser._videoTrack.play(`agitem-${remoteUser.uid}`);
        }
        if (isDesktop) {
          dom.setAttribute("style", `grid-area: span 12/span 24/13/25`);
        }
        else {
          dom.setAttribute("style", `grid-area: span 25/span 24/11/25`);
        }


      }

      if (mediaType === "audio") {
        console.log("subscribe audio success");
        remoteUser.audioTrack.play();
      }
    });

    rt.client.on("user-unpublished", function (user) {
      console.log(user);
      const id = user.uid;
      delete rt.remoteUsers[id];

      if (document.getElementById("agitem-" + id)) {
        document.getElementById("agitem-" + id).remove();
      }
    });
  };

  removeStream = (uid) => {
    this.state.streamList.map((item, index) => {
      if (this.props.uid === uid) {
        this.props.user_leave(uid);
        item.localVideo.close();
        let element = document.querySelector("#agitem-" + uid);
        if (element) {
          element.parentNode.removeChild(element);
        }
        let tempList = [...this.state.streamList];
        tempList.splice(index, 1);
        this.setState({
          streamList: tempList,
        });
      }
    });
  };

  addStream = (stream, push = false) => {
    console.log("add stream");
    let repeatition = this.state.streamList.some((item) => {
      return item.getId() === stream.getId();
    });

    if (repeatition) {
      return;
    }

    if (push) {
      this.setState({
        streamList: this.state.streamList.concat([stream]),
      });
    } else {
      this.setState({
        streamList: [stream].concat(this.state.streamList),
      });
    }
  };

  handleCamera = (e) => {
    e.currentTarget.classList.toggle("off");
    this.localStream.isVideoOn()
      ? this.localStream.disableVideo()
      : this.localStream.enableVideo();
  };

  handleMic = (e) => {
    e.currentTarget.classList.toggle("off");
    this.localStream.isAudioOn()
      ? this.localStream.disableAudio()
      : this.localStream.enableAudio();
  };

  switchDisplay = (e) => {
    if (
      e.currentTarget.classList.contains("disabled") ||
      this.state.streamList.length <= 1
    ) {
      return;
    }
    if (this.state.displayMode === "pip") {
      this.setState({ displayMode: "tile" });
    } else if (this.state.displayMode === "tile") {
      this.setState({ displayMode: "pip" });
    } else if (this.state.displayMode === "share") {
      // do nothing or alert, tbd
    } else {
      console.error("Display Mode can only be tile/pip/share");
    }
  };

  hideRemote = (e) => {
    if (
      e.currentTarget.classList.contains("disabled") ||
      this.state.streamList.length <= 1
    ) {
      return;
    }
    let list;
    let id = this.state.streamList[this.state.streamList.length - 1].getId();
    list = Array.from(document.querySelectorAll(`.agitem:not(#agitem-${id})`));
    list.map((item) => {
      if (item.style.display !== "none") {
        item.style.display = "none";
      } else {
        item.style.display = "block";
      }
    });
  };

  handleExit = (e) => {
    if (e.currentTarget.classList.contains("disabled")) {
      return;
    }
    console.log(this);

    this.props.dispatch(
      leaveLiveStreamUserStart({
        live_stream_id: this.props.stream_id,
        user_id: this.props.uid
      })
    );

    try {
      if (this.props.host_id == this.props.uid) {

        //this.client && Object.keys(this.client).length != 0 && Object.keys(this.localStream).length != 0 && this.client.unpublish(this.localStream);

        if (Object.keys(this.localStream).length != 0) {
          this.localStream && this.localStream.localVideo.stop();
          this.localStream && this.localStream.localVideo.close();
        }
        if (this.state.stateSharing) {
          this.shareClient && this.shareClient.unpublish(this.shareStream);
          this.shareStream && this.shareStream.close();
        }
        this.client &&
          this.client.leave(
            () => {
              console.log("Client succeed to leave.");
            },
            () => {
              console.log("Client failed to leave.");
            }
          );

        // delete live stream user chat
        // this.props.dispatch(
        //   deleteStreamChatStart({
        //     stream_id: this.props.stream_id,
        //   })
        // );
      }

      this.props.user_leave(localStorage.getItem("userId"));
    } finally {
      this.setState({ readyState: false });
      this.client = null;
      this.localStream = null;
    }
  };

  sharingScreen = (e) => {
    if (this.state.stateSharing) {
      this.shareClient && this.shareClient.unpublish(this.shareStream);
      // this.shareStream && this.shareStream.close();
      this.state.stateSharing = false;
    } else {
      this.state.stateSharing = true;
      let $ = this.props;
      // init AgoraRTC local client
      this.shareClient = AgoraRTC.createClient({ mode: $.transcode });
      this.shareClient.init($.appId, () => {
        console.log("AgoraRTC client initialized");
        this.subscribeStreamEvents();
        this.shareClient.join($.appId, $.channel, $.uid, (uid) => {
          this.state.uid = uid;
          console.log("User " + uid + " join channel successfully");
          console.log("At " + new Date().toLocaleTimeString());

          // create local stream
          // It is not recommended to setState in function addStream
          this.shareStream = this.streamInitSharing(
            uid,
            $.attendeeMode,
            $.videoProfile
          );
          this.shareStream.init(
            () => {
              if ($.attendeeMode !== "audience") {
                this.addStream(this.shareStream, true);
                this.shareClient.publish(this.shareStream, (err) => {
                  console.log("Publish local stream error: " + err);
                });
              }
              this.setState({ readyState: true });
            },
            (err) => {
              console.log("getUserMedia failed", err);
              this.setState({ readyState: true });
            }
          );
        });
      });
    }
  };

  streamInitSharing = (uid, attendeeMode, videoProfile, config) => {
    let defaultConfig = {
      streamID: uid,
      audio: true,
      video: false,
      screen: true,
    };

    switch (attendeeMode) {
      case "audio-only":
        defaultConfig.video = false;
        break;
      case "audience":
        defaultConfig.video = false;
        defaultConfig.audio = false;
        break;
      default:
      case "video":
        break;
    }

    let stream = AgoraRTC.createStream(merge(defaultConfig, config));
    stream.setVideoProfile(videoProfile);
    return stream;
  };

  render() {
    const style = {
      display: "grid",
      gridGap: "10px",
      alignItems: "center",
      justifyItems: "center",
      gridTemplateRows: "repeat(12, auto)",
      gridTemplateColumns: "repeat(24, auto)",
    };
    const videoControlBtn =
      this.props.attendeeMode === "video" ? (
        <span
          onClick={this.handleCamera}
          className="ag-btn videoControlBtn"
          title="Enable/Disable Video"
        >
          <i className="ag-icon ag-icon-camera"></i>
          <i className="ag-icon ag-icon-camera-off"></i>
        </span>
      ) : (
        ""
      );

    const audioControlBtn =
      this.props.attendeeMode !== "audience" ? (
        <span
          onClick={this.handleMic}
          className="ag-btn audioControlBtn"
          title="Enable/Disable Audio"
        >
          <i className="ag-icon ag-icon-mic"></i>
          <i className="ag-icon ag-icon-mic-off"></i>
        </span>
      ) : (
        ""
      );

    const switchDisplayBtn = (
      <span
        onClick={this.switchDisplay}
        className={
          this.state.streamList.length > 4
            ? "ag-btn displayModeBtn disabled"
            : "ag-btn displayModeBtn"
        }
        title="Switch Display Mode"
      >
        <i className="ag-icon ag-icon-switch-display"></i>
      </span>
    );
    const hideRemoteBtn = (
      <span
        className={
          this.state.streamList.length > 4 || this.state.displayMode !== "pip"
            ? "ag-btn disableRemoteBtn disabled"
            : "ag-btn disableRemoteBtn"
        }
        onClick={this.hideRemote}
        title="Hide Remote Stream"
      >
        <i className="ag-icon ag-icon-remove-pip"></i>
      </span>
    );
    const exitBtn = (
      <span
        onClick={this.handleExit}
        className={
          this.state.readyState ? "ag-btn exitBtn" : "ag-btn exitBtn disabled"
        }
        title="Exit"
      >
        <Button className="btn-lg1 ">
          <Image
            src={window.location.origin + "/assets/images/icons/end_call.svg"}
          />
        </Button>
      </span>
    );

    const closeSendTipModal = () => {
      this.setState({ sendTip: false });
    };

    const fundMe = (
      <div className="lists-button-group-stream post-icons">
        <Button
          type="button"
          className="g-btn m-rounded m-border m-profile m-with-icon-stream"
          onClick={() => this.setState({ sendTip: true })}
        >
          <Image
            src="/assets/images/icons/tip-white.svg"
            className="svg-clone"
          />
          {isDesktop ? (<span className="stream-tip"> {t("send_tip")}</span>) : ""}
        </Button>
      </div>
    );
    const labelUser = (
      <h4 className="g-btn m-rounded m-border m-profile m-with-icon-stream label-user-count">
        <Image
          src="/assets/images/icons/user-white.svg"
          className="svg-clone" height={"24px"} width={"24px"}
        /> {isDesktop ? `Viewers - ${this.props.user_count}` : this.props.user_count}
      </h4>
    );

    return (
      <div id="ag-canvas" style={style}>
        <div className="ag-viewer-group">
          {localStorage.getItem("userId") != this.props.host_id && fundMe}{" "}
          {labelUser}
        </div>
        <div className="ag-btn-group">
          {exitBtn}

          {/*videoControlBtn*/}
          {/*audioControlBtn*/}
          {/*
            <span
              onClick={this.sharingScreen}
              className="ag-btn shareScreenBtn"
              title="Share/unShare Screen"
            >
              <i className="ag-icon ag-icon-screen-share"></i>
            </span>
          */}
          {/*switchDisplayBtn*/}
          {/*hideRemoteBtn*/}
        </div>
        {localStorage.getItem("userId") != this.props.host_id && (
          <SendTipModal
            sendTip={this.state.sendTip}
            closeSendTipModal={closeSendTipModal}
            username={this.props.host_user?.username}
            userPicture={this.props.host_user?.picture}
            name={
              this.props.host_user?.first_name.concat(
                this.props.host_user?.middle_name,
                this.props.host_user?.last_name
              ) || this.props.host_user?.username
            }
            post_id={null}
            user_id={this.props.host_id}
          />
        )}
        <script></script>
      </div>
    );
  }
}

export default AgoraCanvas;
